import React, { Component } from "react";
import settings from "../../settings.json";
import { withRouter, Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import "./bookingdetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import translate from "../../translate.json";
class Bookingdetails extends Component {
    constructor(props) {
        super(props);
        this.queryString = require("query-string");
        this.urlParameters = this.queryString.parse(this.props.location.search);
        this.axios = require("axios");
        this.settings = {
            domain:
                window.location.hostname !== "localhost"
                    ? window.location.hostname
                    : settings.domain,
            protocol: settings.protocol,
            fromEmail: settings.fromEmail,
        };

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        this.weekDays = days;
        this.monthNames = monthNames;
        this.selectedPackage = [];
        this.serviceFee = 0;
        this.ownerEmail = "";
        this.serviceFeeDiscount = 0;
        this.state = sessionStorage.getItem("currentState")
            ? JSON.parse(sessionStorage.getItem("currentState"))
            : [];

        this.axios
            .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/log.php",
                this.state
            )
        if (this.urlParameters.paymentId) {
            this.getBookedPackage(this.urlParameters.paymentId);
            this.state.cabins = undefined;
            this.state.camping = undefined;
            this.state.packages = [];
        }
        this.detailInfo = sessionStorage.getItem("detailInfo")
            ? JSON.parse(sessionStorage.getItem("detailInfo"))
            : "";
        this.state.firstName =
            this.detailInfo !== "" ? this.detailInfo.firstName : "";
        this.state.phone = this.detailInfo !== "" ? this.detailInfo.phone : "";
        this.state.lastName =
            this.detailInfo !== "" ? this.detailInfo.lastName : "";
        this.state.email = this.detailInfo !== "" ? this.detailInfo.email : "";
        this.state.repeatEmail =
            this.detailInfo !== "" ? this.detailInfo.repeatEmail : "";
        this.state.comment = this.detailInfo !== "" ? this.detailInfo.comment : "";
        this.state.terms = this.detailInfo !== "" ? this.detailInfo.terms : false;
        this.state.coupon = this.detailInfo !== "" ? this.detailInfo.coupon : "";
        this.state.companyEmail = "";
        this.state.companyName = "";
        this.state.companyAddress = "";
        this.state.companyCity = "";
        this.state.companyVat = "";
        this.state.companyPhone = "";
        this.state.paymentMethod = "faktura";

        this.guests = 1;
        const oneDay = 24 * 60 * 60 * 1000;
        var calcDaysStart = new Date(this.state.startDateFormat).setHours(
            0,
            0,
            0,
            0
        );
        var calcDaysEnd = new Date(this.state.endDateFormat).setHours(0, 0, 0, 0);
        this.days = Math.round(Math.abs((calcDaysStart - calcDaysEnd) / oneDay));
        const endDate = new Date(this.state.endDateFormat);
        this.endDate =
            days[endDate.getDay()] +
            " " +
            endDate.getDate() +
            ", " +
            monthNames[endDate.getMonth()] +
            " " +
            endDate.getFullYear();
        const startDate = new Date(this.state.startDateFormat);
        this.endDateInfo = this.endDate;

        this.startDate = [
            days[startDate.getDay()] +
            " " +
            startDate.getDate() +
            ", " +
            monthNames[startDate.getMonth()] +
            " " +
            startDate.getFullYear(),
        ];
        this.startDateInfo = this.startDate;
        this.checkIn =
            days[startDate.getDay()] +
            " " +
            startDate.getDate() +
            "," +
            monthNames[startDate.getMonth()] +
            " " +
            startDate.getFullYear() +
            "<br/> 16.00(4:00 PM)";
        this.checkOut =
            days[endDate.getDay()] +
            " " +
            endDate.getDate() +
            "," +
            monthNames[endDate.getMonth()] +
            " " +
            endDate.getFullYear() +
            "<br/> 11.00(11:00 AM)";
        this.cabinsName = [];
        this.packageName = [];
        this.campingName = [];
        this.maxGuests = 0;
        this.totalPrice = 0;
        this.aPrice = 0;
        this.cPrice = 0;
        this.totalPriceDiscount = -1;
        this.cleaning = 0;
        this.selectedCabinsId = [];
        this.onRequest = "1";
        this.originalPrice = 0;
        this.emailList = [settings.fromEmail];

        if (this.state.cabins !== undefined) {
            this.state.cabins.forEach((value) => {
                if (value.selected === true) {
                    if (value.onRequest !== "1") {
                        this.onRequest = "0";
                    }
                    var mapedCabins = this.state.mapedCabins.find(
                        (x) => x.id === value.id
                    );
                    if (mapedCabins.checkInTime) {
                        this.checkIn =
                            days[startDate.getDay()] +
                            " " +
                            startDate.getDate() +
                            "," +
                            monthNames[startDate.getMonth()] +
                            " " +
                            startDate.getFullYear() +
                            "<br/>" +
                            mapedCabins.checkInTime ?? "";
                    }
                    if (mapedCabins.checkOutTime) {
                        this.checkOut =
                            days[endDate.getDay()] +
                            " " +
                            endDate.getDate() +
                            "," +
                            monthNames[endDate.getMonth()] +
                            " " +
                            endDate.getFullYear() +
                            "<br/>" +
                            mapedCabins.checkOutTime ?? "";
                    }
                    if (mapedCabins.blockDays) {
                        endDate.setDate(
                            endDate.getDate() + JSON.parse(mapedCabins.blockDays)
                        );
                        this.endDateInfo =
                            days[endDate.getDay()] +
                            " " +
                            endDate.getDate() +
                            ", " +
                            monthNames[endDate.getMonth()] +
                            " " +
                            endDate.getFullYear();
                    }
                    if (mapedCabins.blockDays) {
                        startDate.setDate(
                            startDate.getDate() - JSON.parse(mapedCabins.blockDays)
                        );
                        this.startDateInfo = [
                            days[startDate.getDay()] +
                            " " +
                            startDate.getDate() +
                            ", " +
                            monthNames[startDate.getMonth()] +
                            " " +
                            startDate.getFullYear(),
                        ];
                    }

                    this.selectedCabinsId.push(value.id);
                    this.maxGuests += JSON.parse(value.maxGuests);
                    value.price = JSON.stringify(value.price).replace(".", "");
                    this.cabinsName.push(value);

                    this.totalPrice = this.totalPrice + Number(value.price);
                    this.cleaning = this.cleaning + Number(value.cleaning);
                }
            });
        } else if (this.state.camping !== undefined) {

            this.startDate = [];
            this.onRequest = 0;
            this.state.camping.forEach((value) => {
                if (value.selected === true) {

                    this.getDates(startDate, endDate).forEach((element) => {

                        var dateArray = new Date(element);

                        this.startDate.push(
                            days[dateArray.getDay()] +
                            " " +
                            dateArray.getDate() +
                            ", " +
                            monthNames[dateArray.getMonth()] +
                            " " +
                            dateArray.getFullYear()
                        );

                        if (value.selected === true) {
                            this.maxGuests += JSON.parse(value.maxGuests);
                            value.price = value.price.replace(".", "");
                            this.campingName.push(value);
                            this.totalPrice = this.totalPrice + Number(value.price);
                            this.campingId = value.id;
                        }
                    });
                }
            });

        } else {

            this.startDate = [];
            this.selectedPackage = [];
            if (this.state !== []) {
                this.state.packages.forEach((value) => {
                    if (value.selected === true) {
                        this.getDates(value.date, value.date).forEach((element) => {
                            var dateArray = new Date(element);
                            var date =
                                days[dateArray.getDay()] +
                                " " +
                                dateArray.getDate() +
                                ", " +
                                monthNames[dateArray.getMonth()] +
                                " " +
                                dateArray.getFullYear();
                            this.startDate.push(date);
                            this.onRequest = 0;
                            if (value.selected === true) {
                                value.date = date;
                                this.maxGuests += JSON.parse(value.maxGuests);
                                value.price = value.price.replace(".", "");
                                this.packageName.push(value);
                                this.totalPrice = this.totalPrice + Number(value.price);
                                this.aPrice = this.aPrice + Number(value.price);
                                this.cPrice = this.cPrice + Number(value.cPrice);
                                this.packageId = value.id;
                                this.extraInfo = value.extraInfo;
                            }
                        });
                        this.selectedPackage.push(value);
                    }
                });
            }
        }

        var offerPrice = 0;
        var offerPriceExclude = 0;
        if (this.state.offers !== undefined) {
            this.state.offers.forEach((value) => {
                value.price = value.price.replace(".", "");

                if (value.unit === "0") {
                    offerPrice += Number(value.price * this.state.guests);
                    if (value.exclude !== "1") {
                        offerPriceExclude += Number(value.price * this.state.guests);
                    }
                }
                if (value.unit === "1") {
                    offerPrice += Number(value.price * value.pcs);
                    if (value.exclude !== "1") {
                        offerPriceExclude += Number(value.price * value.pcs);
                    }
                }
                if (value.unit === "2") {
                    offerPrice += Number(value.price * this.state.guests * this.days);
                    if (value.exclude !== "1") {
                        offerPriceExclude += Number(
                            value.price * this.state.guests * this.days
                        );
                    }
                }
                if (value.unit === "3") {
                    offerPrice += Number(value.price * this.days);
                    if (value.exclude !== "1") {
                        offerPriceExclude += Number(value.price * this.days);
                    }
                }
                if (value.unit === "4") {
                    offerPrice += Number(value.pcs * value.price * this.days);
                    if (value.exclude !== "1") {
                        offerPriceExclude += Number(value.pcs * value.price * this.days);
                    }
                }
            });
        } else {
            this.state.offers = [];
        }
        this.paymentId = "";

        this.totalPrice = settings.beds24 ? this.totalPrice : this.state.cabins
            ? this.totalPrice * this.days
            : this.totalPrice * this.state.guests;
        if (this.state.packages) {

            this.totalPrice = (this.aPrice * this.state.adults) + (this.cPrice * this.state.children);
        }
        this.serviceFee = Math.round((this.totalPrice + offerPriceExclude) * 0.1);
        this.totalPrice += this.cleaning + offerPrice;
        this.totalPrice += this.state.cabins ? this.serviceFee : 0;
        // this.state = {
        //   clickedBooking: false,
        //   firstName: "",
        //   lastName: "",
        //   email: "",
        //   coupon: "",
        //   discount: "",
        //   guests: this.state.guests,
        //   startDateFormat: this.state.startDateFormat,
        //   endDateFormat: this.state.endDateFormat,
        //   errorFirstName: false,
        //   errorLastName: false,
        //   errorEmail: false,
        //   errorRepeatEmail: false,
        //   errorComment: false
        // };

        this.getSettings();
        this.getCabinSettings();
        this.setOffersEmail();
    }
    getBookedPackage(paymentId) {
        this.axios
            .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/getBookedPackages.php",
                { paymentId }
            )
            .then((booked) => {
                var data = booked.data[0];
                this.axios
                    .post(
                        "" +
                        this.settings.protocol +
                        this.settings.domain +
                        "/static/php/getPackages.php",
                        { packageId: data.packageId }
                    )
                    .then((response) => {
                        var getPackage = response.data[0];
                        this.paymentId = paymentId;
                        this.startDate = new Array(data.date);
                        getPackage.name = getPackage.packageName;
                        getPackage.available = true;
                        getPackage.selected = true;
                        getPackage.paymentLink = true;
                        getPackage.weekDays = JSON.parse(getPackage.weekDays);
                        getPackage.date = data.date;
                        this.maxGuests = getPackage.maxGuests;
                        this.packageName = new Array(getPackage);
                        this.selectedPackage = new Array(getPackage);
                        this.guests = data.guests;
                        this.originalPrice = Number(getPackage.price) * this.guests;
                        this.totalPrice = data.costs;
                        this.packageId = getPackage.id;
                        this.onRequest = "0";
                        this.setState({
                            guests: data.guests === "" ? 0 : JSON.parse(data.guests),
                            adults: data.adults === "" ? 0 : JSON.parse(data.adults),
                            children: data.children === "" ? 0 : JSON.parse(data.children),
                            firstName: data.firstName,
                            lastName: data.lastName,
                            phone: data.phone,
                            email: data.email,
                            repeatEmail: data.email,
                            comment: data.comment,
                            packages: getPackage,
                        });
                    });
            });
    }
    setOffersEmail() {
        this.state.offers.forEach((value) => {
            if (value.email !== "" && value.email !== null) {
                if (!this.emailList.includes(value.email)) {
                    this.emailList.push(value.email);
                }
            }
        });
    }
    getCabinSettings() {
        if (this.selectedCabinsId.length > 0) {
            this.axios
                .post(
                    "" +
                    this.settings.protocol +
                    this.settings.domain +
                    "/static/php/getCabinSettings.php",
                    { id: this.selectedCabinsId }
                )
                .then((response) => {
                    if (response.data !== "") {
                        response.data.forEach((value) => {
                            if (value.emailCleaning !== "" && value.emailCleaning !== null) {
                                this.emailList.push(value.emailCleaning);
                            }
                            if (value.emailUser !== "" && value.emailUser !== null) {
                                this.ownerEmail = value.emailUser;
                                this.emailList.push(value.emailUser);
                            }
                        });
                    }
                });
        }
    }
    getDates(start, end) {
        var arr = [];
        var dt = new Date(start);
        end = new Date(end);
        while (dt <= end) {
            arr.push(new Date(dt).valueOf());
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }
    getSettings() {
        this.axios
            .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/getSettings.php"
            )
            .then((response) => {
                this.setState({
                    companyFromEmail: this.settings.fromEmail,
                    companyEmail: response.data[0].email,
                    companyName: response.data[0].name,
                    companyAddress: response.data[0].adress,
                    companyCity: response.data[0].city,
                    companyPhone: response.data[0].phone,
                    companyVat: response.data[0].vatnr,
                    paymentMethod: response.data[0].payment,
                });
            });
    }
    validateEmail(email) {
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validateLetters(value) {
        let re = /'|"|&/g;
        return re.test(value);
    }
    getCoupon(coupon) {

        var cabinId = this.cabinsName.length > 0 ? this.cabinsName[0].id : false;
        var campingId = this.campingId;


        this.axios
            .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/getCoupons.php",
                { coupon, cabinId, campingId }
            )
            .then((response) => {

                response.data = response.data === "" ? [] : response.data;
                let valid = false;
                if (cabinId) {
                    valid = response.data.some((x) => this.selectedCabinsId.includes(x.cabinId))
                }
                if (campingId) {
                    valid = response.data.some((x) => campingId === x.campingId)
                }

                if (
                    valid
                ) {
                    if (response.data) {
                        const d = new Date();
                        d.setHours(0, 0, 0, 0);
                        var currentDate = d.valueOf();
                        var endDate = new Date(response.data[0].endDate).valueOf();
                        var startDate = new Date(response.data[0].startDate).valueOf();

                        if (
                            (currentDate >= startDate && currentDate <= endDate) ||
                            (response.data[0].endDate === "" && currentDate >= startDate)
                        ) {
                            if (response.data[0].maxUse === "") {
                                this.setState({
                                    discount: response.data[0].discount,
                                    coupon,
                                });
                            } else if (
                                response.data[0].maxUse > response.data[0].usedCoupon
                            ) {
                                this.setState({
                                    discount: response.data[0].discount,
                                    coupon,
                                });
                            } else {
                                this.setState({
                                    discount: "",
                                    coupon: "",
                                });
                            }
                        } else {
                            this.setState({
                                discount: "",
                                coupon: "",
                            });
                        }
                    }
                } else {
                    this.setState({
                        discount: "",
                        coupon: "",
                    });
                }
            });
    }

    deatilForm(event, input) {
        if (input === "coupon") {
            this.getCoupon(event.target.value);
        }
        if (input === "firstName") {
            this.setState({
                firstName: event.target.value,
            });
        }
        if (input === "phone") {
            this.setState({
                phone: event.target.value,
            });
        }
        if (input === "terms") {
            this.setState({
                terms: event.target.checked,
            });
        }
        if (input === "lastName") {
            this.setState({
                lastName: event.target.value,
            });
        }
        if (input === "email") {
            this.setState({
                email: event.target.value.replace(/ /g, ""),
            });
        }
        if (input === "repeatEmail") {
            this.setState({
                repeatEmail: event.target.value.replace(/ /g, ""),
            });
        }
        if (input === "comment") {
            this.setState({
                comment: event.target.value
                    .replace(/&/g, "")
                    .replace(/'/g, "‘")
                    .replace(/"/g, "”"),
            });
        }
    }

    getBookingDates() {
        const url = this.state.cabins
            ? "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/getBooking.php"
            : "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/getBookedPackages.php";
        return this.axios
            .post(url)
            .then(function (response) {
                // handle success
                return response.data;
            })
            .catch(function (error) {
                // handle error
                return error;
            });
    }

    completeBooking() {
        var error = {};
        if (
            this.state.firstName.length < 3 ||
            this.validateLetters(this.state.firstName)
        ) {
            error.errorFirstName =
                "First name must contain at least 3 characters and only letters";
        } else {
            error.errorFirstName = false;
        }
        this.setState(error);
        if (
            this.state.lastName.length < 3 ||
            this.validateLetters(this.state.lastName)
        ) {
            error.errorLastName =
                "Last name must contain at least 3 characters and only letters";
        } else {
            error.errorLastName = false;
        }
        if (this.state.phone.length < 6) {
            error.errorPhone = "Please provide a phone number";
        } else {
            error.errorPhone = false;
        }
        this.setState(error);
        if (this.state.terms === undefined || this.state.terms === false) {
            error.errorTerms = "Please accept terms and condition";
        } else {
            error.errorTerms = false;
        }
        this.setState(error);
        if (!this.validateEmail(this.state.email)) {
            error.errorEmail = "Email is not a valid email";
        } else {
            error.errorEmail = false;
        }
        this.setState(error);
        if (this.state.repeatEmail !== this.state.email) {
            error.errorRepeatEmail = "Confirm email is not equal to email";
        } else {
            error.errorRepeatEmail = false;
        }
        this.setState(error);
        if (this.validateLetters(this.state.comment)) {
            error.errorComment = "Text must only contain letters and numbers";
        } else {
            error.errorComment = false;
        }
        this.setState(error);

        if (
            error.errorComment === false &&
            error.errorRepeatEmail === false &&
            error.errorEmail === false &&
            error.errorPhone === false &&
            error.errorLastName === false &&
            error.errorFirstName === false &&
            error.errorTerms === false
        ) {
            var alreadyBooked = true;
            this.setState({ clickedBooking: true });

            this.getBookingDates()
                .then((data) => {
                    data = data.length > 0 ? data : [];

                    let count = 0;
                    if (this.state.cabins) {
                        data.forEach((value, i) => {
                            var checkOut = new Date(value.checkOut.replace(",", ""));
                            checkOut.setDate(checkOut.getDate() - 1);
                            if (
                                ((new Date(value.checkIn.replace(",", "")).valueOf() <=
                                    new Date(this.startDate).valueOf() &&
                                    checkOut.valueOf() >= new Date(this.startDate).valueOf()) ||
                                    (new Date(value.checkIn.replace(",", "")).valueOf() <
                                        new Date(this.endDate).valueOf() &&
                                        checkOut.valueOf() > new Date(this.endDate).valueOf())) &&
                                (value.paymentStatus === "Paid" ||
                                    value.paymentStatus === "Faktura")
                            ) {
                                JSON.parse(value.cabins).forEach((cabin) => {
                                    if (this.cabinsName.findIndex((x) => x.name === cabin) >= 0) {
                                        alreadyBooked = false;
                                    }
                                });
                            }
                        });
                    } else if (this.state.package) {
                        data.forEach((value, i) => {
                            if (
                                new Date(value.date).valueOf() ===
                                new Date(this.startDate).valueOf() &&
                                (value.paymentStatus === "Paid" ||
                                    value.paymentStatus === "Faktura") &&
                                this.packageName.some((x) => x.id === value.packageId)
                            ) {
                                count = count + JSON.parse(value.guests);
                            }
                        });
                    } else {
                        data.forEach((value, i) => {
                            if (
                                new Date(value.date).valueOf() ===
                                new Date(this.startDate).valueOf() &&
                                (value.paymentStatus === "Paid" ||
                                    value.paymentStatus === "Faktura") &&
                                this.campingName.some((x) => x.id === value.campingId)
                            ) {
                                count = count + JSON.parse(value.guests);
                            }
                        });
                    }
                    if (count + this.state.guests > JSON.parse(this.maxGuests)) {
                        alreadyBooked = false;
                    }
                })
                .then(() => {
                    const paymentId =
                        this.paymentId === ""
                            ? Math.floor(Math.random() * 90000) + 10000
                            : this.paymentId;
                    var packageInfo = [];

                    if (this.selectedPackage.length > 0) {
                        this.selectedPackage.forEach((value) => {
                            var info = {
                                name: value.name,
                                id: value.id,
                                price: this.totalPrice,
                                aPrice: this.aPrice,
                                cPrice: this.cPrice,
                                guests: this.state.guests,
                                adults: this.state.adults,
                                children: this.state.children,
                                date: value.date,
                                paymentLink: value.paymentLink ? value.paymentLink : false,
                            };
                            packageInfo.push(info);
                        });
                    }
                    if (alreadyBooked) {
                        var info = {
                            packageInfo,
                            paymentId: paymentId,
                            emailList: this.emailList,
                            firstName: this.state.firstName,
                            ownerEmail: this.ownerEmail,
                            email: this.state.email,
                            phone: this.state.phone,
                            companyEmail: this.state.companyEmail,
                            companyFromEmail: this.state.companyEmail,
                            companyName: this.state.companyName,
                            companyAddress: this.state.companyAddress,
                            companyCity: this.state.companyCity,
                            companyPhone: this.state.companyPhone,
                            companyVat: this.state.companyVat,
                            domain: this.settings.domain,
                            protocol: this.settings.protocol,
                            onRequest: this.onRequest,
                            offers: this.state.offers,
                            serviceFee: this.serviceFee,
                            costs:
                                this.totalPriceDiscount === -1
                                    ? this.totalPrice
                                    : this.totalPriceDiscount,
                            endDate:
                                this.endDate !== this.endDateInfo
                                    ? this.endDateInfo
                                    : this.endDate,
                            startDate:
                                this.startDate !== this.startDateInfo
                                    ? this.startDateInfo[0]
                                    : this.startDate[0],
                            date: this.state.startDate,
                            totalNight: this.days,
                            guests: this.state.guests,
                            coupon: this.state.coupon,
                            cabins: this.cabinsName,
                            package:
                                this.packageName.length > 0 ? this.packageName[0].name : "",
                            camping:
                                this.campingName.length > 0 ? this.campingName[0].name : "",
                            discount: this.state.discount ? this.state.discount : false,
                            hostname: window.location.origin,
                            paymentMethod: this.state.paymentMethod,
                        };

                        this.axios
                            .post(
                                "" +
                                this.settings.protocol +
                                this.settings.domain +
                                "/static/php/bookingDetails.php",
                                {
                                    packageInfo,
                                    roomId: this.state.roomId,
                                    firstName: this.state.firstName,
                                    extraInfo: this.extraInfo ? this.extraInfo : "",
                                    lastName: this.state.lastName,
                                    email: this.state.email,
                                    phone: this.state.phone,
                                    days: this.days,
                                    companyEmail: this.state.companyEmail,
                                    onRequest: this.onRequest,
                                    companyName: this.state.companyName,
                                    companyAddress: this.state.companyAddress,
                                    companyCity: this.state.companyCity,
                                    companyPhone: this.state.companyPhone,
                                    companyVat: this.state.companyVat,
                                    domain: this.settings.domain,
                                    protocol: this.settings.protocol,
                                    comments: this.state.comment,
                                    guests: this.state.guests,
                                    adults: this.state.adults,
                                    children: this.state.children ?? "",
                                    date: this.state.startDate,
                                    serviceFee: this.serviceFee,
                                    offers: this.state.offers,
                                    checkIn: this.checkIn,
                                    checkOut: this.checkOut,
                                    startDate:
                                        this.cabinsName ? this.startDate :
                                            this.startDate !== this.startDateInfo
                                                ? this.startDateInfo
                                                : this.startDate,
                                    endDate:
                                        this.endDate !== this.endDateInfo
                                            ? this.endDateInfo
                                            : this.endDate,
                                    totalNight: this.days,
                                    cabins: this.cabinsName,
                                    cleaning: this.cleaning,
                                    selectedCabinsId: this.selectedCabinsId,
                                    package:
                                        this.packageName.length > 0 ? this.packageName[0].name : "",
                                    camping:
                                        this.campingName.length > 0 ? this.campingName[0].name : "",
                                    costs:
                                        this.totalPriceDiscount === -1
                                            ? this.totalPrice
                                            : this.totalPriceDiscount,
                                    paymentId: paymentId,
                                    id: this.packageId ? this.packageId : this.campingId,
                                    coupon: this.state.coupon,
                                    discount: this.state.discount ? this.state.discount : false,
                                }
                            )
                            .then((response) => {
                                if (this.packageId && this.state.mapedPackages) {
                                    if (
                                        this.state.mapedPackages.some(
                                            (x) => x.paymentMethod === "1" && x.selected
                                        )
                                    ) {
                                        this.state.paymentMethod = "faktura";
                                    }

                                }

                                if (
                                    this.totalPriceDiscount === 0 ||
                                    this.state.paymentMethod === "faktura"
                                ) {
                                    this.axios
                                        .post(
                                            "" +
                                            this.settings.protocol +
                                            this.settings.domain +
                                            "/static/php/callbackurl.php",
                                            info
                                        )
                                        .then((response) => {

                                            if (response.data.includes("Error")) {
                                                this.setState({ clickedBooking: false });
                                                alert(response.data);
                                            } else {

                                                sessionStorage.setItem(paymentId, JSON.stringify(info));
                                                window.location.href = response.data;
                                            }
                                        });
                                } else if (this.onRequest === "1") {
                                    this.axios
                                        .post(
                                            "" +
                                            this.settings.protocol +
                                            this.settings.domain +
                                            "/static/php/onRequest.php",
                                            info
                                        )
                                        .then((response) => {
                                            sessionStorage.setItem(paymentId, JSON.stringify(info));
                                            if (response.data.includes("Error")) {
                                                this.setState({ clickedBooking: false });
                                                alert(response.data);
                                            } else {
                                                window.location.href =
                                                    this.settings.protocol +
                                                    this.settings.domain +
                                                    "/#/message?onRequest=true";
                                            }
                                        });
                                } else {
                                    this.axios
                                        .post(
                                            "" +
                                            this.settings.protocol +
                                            this.settings.domain +
                                            "/static/php/quickpay/bookedClient.php",
                                            info
                                        )
                                        .then((response) => {

                                            sessionStorage.setItem(paymentId, JSON.stringify(info));
                                            window.location.href = response.data;
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            return error;
                                        });
                                    return response.data;
                                }
                            })
                            .catch((error) => {
                                return error;
                            });
                    } else {
                        this.setState({ clickedBooking: false });
                        alert("The selected dates are already fully booked");
                    }
                });
        } else {
            console.log(error);
        }
    }
    termsCondition() {
        sessionStorage.setItem("lastScrollPosition", window.scrollY + 200);
        sessionStorage.setItem("detailInfo", JSON.stringify(this.state));
    }
    render() {
        if (this.state.coupon) {
            this.totalPriceDiscount =
                this.totalPrice -
                Math.round((this.totalPrice / 100) * this.state.discount);
            if (this.state.cabins) {
                this.serviceFeeDiscount =
                    this.serviceFee -
                    Math.round((this.serviceFee / 100) * this.state.discount);
            }
        } else {
            this.totalPriceDiscount = -1;
        }

        const cabinOffers = this.state.offers.map((value, i) => {
            return (
                <span key={i}>
                    {value.name}
                    <br />

                    <span className="price">
                        DKK {value.price},-{" "}
                        <span className="per-night">
                            {" "}
                            {value.unit === "0" ? translate.perPer[settings.lang] : ""}
                            {value.unit === "1"
                                ? +" " + value.pcs + "x " + translate.pcs[settings.lang]
                                : ""}
                            {value.unit === "4"
                                ? +" " + value.pcs + "x " + translate.pcs[settings.lang]
                                : ""}
                            {value.unit === "2"
                                ? translate.perPer[settings.lang] +
                                ", " +
                                translate.perDay[settings.lang]
                                : ""}
                            {value.unit === "3" ? translate.perDay[settings.lang] : ""}
                        </span>
                    </span>

                    <br />
                </span>
            );
        });
        const cabinOffersSummary = this.state.offers.map((value, i) => {
            return (
                <span key={i}>
                    <span className="cabin-name">{value.name}</span>
                    <span className="price">
                        DKK {value.unit === "0" ? value.price * this.state.guests : ""}
                        {value.unit === "1" ? value.price * value.pcs : ""}
                        {value.unit === "2"
                            ? value.price * this.state.guests * this.days
                            : ""}
                        {value.unit === "3" ? value.price * this.days : ""}

                        {value.unit === "4" ? value.price * value.pcs * this.days : ""}
                        ,-
                    </span>

                    <br />
                </span>
            );
        });
        const cabinsName = this.cabinsName.map((value, i) => {
            console.log(value)
            let beds24Price;
            if (settings.beds24) {
                beds24Price = JSON.stringify(value.price / this.days);
            }
            return (
                <span key={i}>
                    {value.name}
                    <br />

                    <span className="price">
                        DKK {beds24Price ?? value.price},-{" "}
                        <span className="per-night">
                            {translate.prNight[settings.lang]}
                        </span>
                    </span>
                    {value.specialComments !== "" ? (
                        <span className="note">
                            {ReactHtmlParser(value.specialComments)}
                        </span>
                    ) : (
                        ""
                    )}

                    <br />
                </span>
            );
        });
        var packageInclude = [];
        const packageName = this.packageName.map((value, i) => {
            if (!packageInclude.includes(value.id)) {
                packageInclude.push(value.id);

                return (
                    <span key={i}>
                        {value.name}
                        <br />
                        <span className="price">
                            DKK {value.price},-{" "}
                            <span className="per-night">
                                {translate.perAdult[settings.lang]}
                            </span>
                            {this.state.children ? <div>
                                DKK {value.cPrice},-{" "}
                                <span className="per-night">
                                    {translate.perChildren[settings.lang]}
                                </span>
                            </div> : ''}
                        </span>
                        <br />
                    </span>
                );
            } else {
                return null;
            }
        });

        const cabinsNamePrice = this.cabinsName.map((value, i) => {
            var pricePerDay = [];
            for (var s = 0; s < this.days; s++) {
                pricePerDay.push({ name: value.name, price: value.price });
            }

            return (
                <span key={i}>
                    <span className="cabin-name">{value.name}</span>
                    <span className="price">DKK {value.price * (settings.beds24 ? 1 : this.days)},-</span>
                    {value.cleaning !== "0" ? (
                        <span className="cleaning">
                            {translate.prClean[settings.lang]}
                            <span className="price">DKK {value.cleaning},- </span>
                        </span>
                    ) : (
                        ""
                    )}
                </span>
            );
        });
        const serviceFee = this.state.cabins ? (
            <span className="service-fee">
                <span className="cabin-name">{translate.fee[settings.lang]}</span>
                <span className="price">
                    {" "}
                    {this.totalPriceDiscount === -1
                        ? this.serviceFee
                        : this.serviceFeeDiscount}
                    ,-
                </span>

                <br />
                <br />
            </span>
        ) : (
            ""
        );
        const packageNamePrice = this.packageName.map((value, i) => {
            var pricePerDay = [];
            for (var s = 0; s < this.days; s++) {
                pricePerDay.push({ name: value.name, price: value.price });
            }


            var children = this.state.children ? <span>
                {value.name} <span style={{ fontSize: '12px' }}>{translate.children[settings.lang]}</span>
                <span className="price">DKK {value.cPrice * this.state.children},-</span>
            </span> : false
            var adults = this.state.adults ? <span>
                {value.name} <span style={{ fontSize: '12px' }}>{translate.adults[settings.lang]}</span>
                <span className="price">DKK {value.price * this.state.adults},-</span>
            </span> : false

            return (
                <span key={i}>
                    {adults}
                    {children}
                </span>
            );
        });
        return (
            <content id="bookingdetails">
                <div className="headline">
                    <h1>{translate.entDetail[settings.lang]}</h1>
                </div>
                <div className="wrap-detail-content">
                    <div className="details-form">
                        <div className="first-name">
                            <p>{translate.name[settings.lang]}</p>
                            <input
                                onChange={(event) => {
                                    this.deatilForm(event, "firstName");
                                }}
                                type="text"
                                name="firstName"
                                defaultValue={this.state.firstName}
                                placeholder={translate.name[settings.lang]}
                            />
                            {this.state.errorFirstName ? (
                                <div className="error-message">{this.state.errorFirstName}</div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="last-name">
                            <p>{translate.lastName[settings.lang]}</p>
                            <input
                                onChange={(event) => {
                                    this.deatilForm(event, "lastName");
                                }}
                                type="text"
                                defaultValue={this.state.lastName}
                                name="lastName"
                                placeholder={translate.lastName[settings.lang]}
                            />
                            {this.state.errorLastName ? (
                                <div className="error-message">{this.state.errorLastName}</div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="phone">
                            <p>{translate.phone[settings.lang]}</p>
                            <input
                                onChange={(event) => {
                                    this.deatilForm(event, "phone");
                                }}
                                type="phone"
                                defaultValue={this.state.phone}
                                name="phone"
                                placeholder={translate.phone[settings.lang]}
                            />
                            {this.state.errorPhone ? (
                                <div className="error-message">{this.state.errorPhone}</div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="email">
                            <p>{translate.email[settings.lang]}</p>
                            <input
                                onChange={(event) => {
                                    this.deatilForm(event, "email");
                                }}
                                type="email"
                                defaultValue={this.state.email}
                                name="email"
                                placeholder={translate.email[settings.lang]}
                            />
                            {this.state.errorEmail ? (
                                <div className="error-message">{this.state.errorEmail}</div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="repeat-email">
                            <p>{translate.confEmail[settings.lang]}</p>
                            <input
                                onChange={(event) => {
                                    this.deatilForm(event, "repeatEmail");
                                }}
                                type="email"
                                defaultValue={this.state.repeatEmail}
                                name="email"
                                placeholder={translate.confEmail[settings.lang]}
                            />
                            {this.state.errorRepeatEmail ? (
                                <div className="error-message">
                                    {this.state.errorRepeatEmail}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="comment">
                            <p>{translate.comment[settings.lang]}</p>
                            <div>
                                <textarea
                                    onChange={(event) => {
                                        this.deatilForm(event, "comment");
                                    }}
                                    type="text"
                                    defaultValue={this.state.comment}
                                    name="comment"
                                    placeholder={translate.comment[settings.lang]}
                                />
                                {this.state.errorComment ? (
                                    <div className="error-message">{this.state.errorComment}</div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="terms">
                            <div>
                                <input
                                    onChange={(event) => {
                                        this.deatilForm(event, "terms");
                                    }}
                                    id="terms"
                                    defaultChecked={this.state.terms}
                                    type="checkbox"
                                />
                                <label htmlFor="terms">{translate.approveTerms[settings.lang]}</label>
                            </div>
                            <Link
                                onClick={() => {
                                    this.termsCondition();
                                }}
                                to={"terms"}
                            >
                                {translate.readTerms[settings.lang]}
                            </Link>
                            {this.state.errorTerms ? (
                                <div className="error-message">{this.state.errorTerms}</div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            onClick={
                                this.state.clickedBooking
                                    ? () => { }
                                    : () => {
                                        this.completeBooking();
                                    }
                            }
                            className="all-btn"
                        >
                            <div>
                                {this.state.clickedBooking ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : this.onRequest === "1" ? (
                                    "Request booking"
                                ) : (
                                    "Complete your booking"
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="booking-details">
                        {cabinsName.length > 0 ? (
                            <div>
                                <h3> {translate.confirmDetails[settings.lang]}</h3>
                                <p className="detail-headline">
                                    {translate.checkIn[settings.lang]}
                                </p>

                                <p>{this.startDate}</p>
                                <p className="detail-headline">
                                    {translate.checkOut[settings.lang]}
                                </p>
                                <p>
                                    {this.endDate === this.endDateInfo
                                        ? this.endDateInfo
                                        : this.endDate}
                                </p>
                                <p className="detail-headline">
                                    {translate.confirmLength[settings.lang]}
                                </p>
                                <p>{this.days} nights</p>
                                <p className="detail-headline">
                                    {translate.ySelcted[settings.lang]}
                                </p>
                                <div>{cabinsName}</div>
                                <p>{cabinOffers}</p>
                                <h3>{translate.yPrice[settings.lang]}</h3>
                                <p>{cabinsNamePrice}</p>
                                {cabinOffersSummary.length > 0 ? (
                                    <span>
                                        <strong>{translate.extra[settings.lang]}:</strong>
                                        <p>{cabinOffersSummary}</p>
                                    </span>
                                ) : (
                                    ""
                                )}
                                <p>{serviceFee}</p>
                            </div>
                        ) : (
                            <div>
                                <h3>{translate.yBooking[settings.lang]}</h3>
                                <p className="detail-headline">
                                    {translate.date[settings.lang]}
                                </p>
                                {this.startDate.map((element, index) => {
                                    element = element.replace(",", " ")

                                    var date = new Date(element);
                                    var beautify = this.weekDays[date.getDay()] +
                                        " " +
                                        date.getDate() +
                                        ", " +
                                        this.monthNames[date.getMonth()] +
                                        " " +
                                        date.getFullYear();
                                    return <div key={index}>{beautify}</div>;
                                })}
                                {this.state.adults || this.state.children ?
                                    ""
                                    :
                                    <span>
                                        <p className="detail-headline">
                                            {this.state.camping !== undefined ? translate.spots[settings.lang] : translate.guests[settings.lang]}
                                        </p>

                                        <p>{this.state.guests}</p>
                                    </span>
                                }
                                {this.state.adults > 0 ?
                                    <span>
                                        <p className="detail-headline">
                                            {translate.adults[settings.lang]}
                                        </p>

                                        <p>{this.state.adults}</p>
                                    </span> : ''}
                                {this.state.children ?
                                    <span>
                                        <p className="detail-headline">
                                            {translate.children[settings.lang]}
                                        </p>

                                        <p>{this.state.children}</p>
                                    </span> : ''}
                                <p className="detail-headline">
                                    {translate.ySelcted[settings.lang]}
                                </p>
                                <p>{packageName}</p>
                                <h3>{translate.yPrice[settings.lang]}</h3>
                                <p className="packageNamePrice">{packageNamePrice}</p>
                            </div>
                        )}
                        {this.state.coupon ? (
                            <p>
                                {translate.coupon[settings.lang]}
                                <span className="price">-{this.state.discount}%</span>
                            </p>
                        ) : (
                            ""
                        )}

                        <p
                            style={{
                                display:
                                    this.originalPrice === this.totalPrice ? "block" : "none",
                            }}
                            className="originalPrice"
                        >
                            {translate.coupon[settings.lang]}{" "}
                            <span className="price">
                                DKK {this.originalPrice}
                                ,-
                            </span>
                        </p>
                        <p className="detail-headline">
                            {this.originalPrice !== ""
                                ? translate.yPrice[settings.lang]
                                : translate.total[settings.lang]}{" "}
                            <span className="price">
                                DKK{" "}
                                {this.totalPriceDiscount === -1
                                    ? this.totalPrice
                                    : this.totalPriceDiscount.toFixed(2)}
                                ,-
                            </span>
                        </p>
                        <div className="coupon">
                            <h3> {translate.coupon[settings.lang]}</h3>
                            <input
                                onBlur={(event) => {
                                    this.deatilForm(event, "coupon");
                                }}
                                defaultValue={this.state.coupon}
                                type="text"
                                name="coupon"
                                placeholder="Coupon"
                            />
                        </div>
                    </div>
                </div>
            </content>
        );
    }
}
export default withRouter(Bookingdetails);
