import React, { Component } from "react";
import settings from "../../../settings.json";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-bootstrap-daterangepicker";
import {
  faTrashAlt,
  faPaperPlane,
  faTimes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./overview.scss";
import { MobileView, BrowserView, ConsoleView } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import { data } from "jquery";

class Overview extends Component {
  uuidv4 = uuidv4;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  constructor(props) {
    super(props);
    this.calendarLabel = '';
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };
    this.userLogin = sessionStorage.getItem("userLogin")
      ? JSON.parse(sessionStorage.getItem("userLogin"))
      : false;
    this.state = {
      selectedCabin: 'All',
      updateState: false,
      selectedDate: null,
    };

    this.axios = require("axios");
    this.bookedDates = [];
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.mapedCabins = [];
    this.allCabins = [];
    this.guestsSelecedDate = [];
    this.cabinExists = true;
    this.cabins = [];
    this.colorArray = [
      "#63b598",
      "#ce7d78",
      "#ea9e70",
      "#a48a9e",
      "#c6e1e8",
      "#648177",
      "#0d5ac1",
      "#f205e6",
      "#1c0365",
      "#14a9ad",
      "#4ca2f9",
      "#a4e43f",
      "#d298e2",
      "#6119d0",
      // "#d2737d",
      "#c0a43c",
      "#f2510e",
      "#651be6",
      "#79806e",
      "#61da5e",
      "#cd2f00",
      "#9348af",
      "#01ac53",
      "#c5a4fb",
      "#996635",
      "#b11573",
      "#4bb473",
      "#75d89e",
      "#2f3f94",
      "#2f7b99",
      "#da967d",
      "#34891f",
      "#b0d87b",
      "#ca4751",
      "#7e50a8",
      "#c4d647",
      "#e0eeb8",
      "#11dec1",
      "#289812",
      "#566ca0",
      "#ffdbe1",
      "#2f1179",
      "#935b6d",
      "#916988",
      "#513d98",
      "#aead3a",
      "#9e6d71",
      "#4b5bdc",
      "#0cd36d",
      "#250662",
      "#cb5bea",
      "#228916",
      "#ac3e1b",
      "#df514a",
      "#539397",
      "#880977",
      "#f697c1",
      "#ba96ce",
      "#679c9d",
      "#c6c42c",
      "#5d2c52",
      "#48b41b",
      "#e1cf3b",
      "#5be4f0",
      "#57c4d8",
      "#a4d17a",
      "#225b8",
      "#be608b",
      "#96b00c",
      "#088baf",
      "#f158bf",
      "#e145ba",
      "#ee91e3",
      "#05d371",
      "#5426e0",
      "#4834d0",
      "#802234",
      "#6749e8",
      "#0971f0",
      "#8fb413",
      "#b2b4f0",
      "#c3c89d",
      "#c9a941",
      "#41d158",
      "#fb21a3",
      "#51aed9",
      "#5bb32d",
      "#807fb",
      "#21538e",
      "#89d534",
      "#d36647",
      "#7fb411",
      "#0023b8",
      "#3b8c2a",
      "#986b53",
      "#f50422",
      "#983f7a",
      "#ea24a3",
      "#79352c",
      "#521250",
      "#c79ed2",
      "#d6dd92",
      "#e33e52",
      "#b2be57",
      "#fa06ec",
      "#1bb699",
      "#6b2e5f",
      "#64820f",
      "#1c271",
      "#21538e",
      "#89d534",
      "#d36647",
      "#7fb411",
      "#0023b8",
      "#3b8c2a",
      "#986b53",
      "#f50422",
      "#983f7a",
      "#ea24a3",
      "#79352c",
      "#521250",
      "#c79ed2",
      "#d6dd92",
      "#e33e52",
      "#b2be57",
      "#fa06ec",
      "#1bb699",
      "#6b2e5f",
      "#64820f",
      "#1c271",
      "#9cb64a",
      "#996c48",
      "#9ab9b7",
      "#06e052",
      "#e3a481",
      "#0eb621",
      "#fc458e",
      "#b2db15",
      "#aa226d",
      "#792ed8",
      "#73872a",
      "#520d3a",
      "#cefcb8",
      "#a5b3d9",
      "#7d1d85",
      "#c4fd57",
      "#f1ae16",
      "#8fe22a",
      "#ef6e3c",
      "#243eeb",
      "#1dc18",
      "#dd93fd",
      "#3f8473",
      "#e7dbce",
      "#421f79",
      "#7a3d93",
      "#635f6d",
      "#93f2d7",
      "#9b5c2a",
      "#15b9ee",
      "#0f5997",
      "#409188",
      "#911e20",
      "#1350ce",
      "#10e5b1",
      "#fff4d7",
      "#cb2582",
      "#ce00be",
      "#32d5d6",
      "#17232",
      "#608572",
      "#c79bc2",
      "#00f87c",
      "#77772a",
      "#6995ba",
      "#fc6b57",
      "#f07815",
      "#8fd883",
      "#060e27",
      "#96e591",
      "#21d52e",
      "#d00043",
      "#b47162",
      "#1ec227",
      "#4f0f6f",
      "#1d1d58",
      "#947002",
      "#bde052",
      "#e08c56",
      "#28fcfd",
      "#bb09b",
      "#36486a",
      "#d02e29",
      "#1ae6db",
      "#3e464c",
      "#a84a8f",
      "#911e7e",
      "#3f16d9",
      "#0f525f",
      "#ac7c0a",
      "#b4c086",
      "#c9d730",
      "#30cc49",
      "#3d6751",
      "#fb4c03",
      "#640fc1",
      "#62c03e",
      "#d3493a",
      "#88aa0b",
      "#406df9",
      "#615af0",
      "#4be47",
      "#2a3434",
      "#4a543f",
      "#79bca0",
      "#a8b8d4",
      "#00efd4",
      "#7ad236",
      "#7260d8",
      "#1deaa7",
      "#06f43a",
      "#823c59",
      "#e3d94c",
      "#dc1c06",
      "#f53b2a",
      "#b46238",
      "#2dfff6",
      "#a82b89",
      "#1a8011",
      "#436a9f",
      "#1a806a",
      "#4cf09d",
      "#c188a2",
      "#67eb4b",
      "#b308d3",
      "#fc7e41",
      "#af3101",
      "#ff065",
      "#71b1f4",
      "#a2f8a5",
      "#e23dd0",
      "#d3486d",
      "#00f7f9",
      "#474893",
      "#3cec35",
      "#1c65cb",
      "#5d1d0c",
      "#2d7d2a",
      "#ff3420",
      "#5cdd87",
      "#a259a4",
      "#e4ac44",
      "#1bede6",
      "#8798a4",
      "#d7790f",
      "#b2c24f",
      "#de73c2",
      "#d70a9c",
      "#25b67",
      "#88e9b8",
      "#c2b0e2",
      "#86e98f",
      "#ae90e2",
      "#1a806b",
      "#436a9e",
      "#0ec0ff",
      "#f812b3",
      "#b17fc9",
      "#8d6c2f",
      "#d3277a",
      "#2ca1ae",
      "#9685eb",
      "#8a96c6",
      "#dba2e6",
      "#76fc1b",
      "#608fa4",
      "#20f6ba",
      "#07d7f6",
      "#dce77a",
      "#77ecca",
    ];
    this.calendar();
    // const xmlString =
    //   "<request<username>utsynid@gmail.com</username> <password>73tranni</password><hotel_id>2815909</hotel_id></request>";
    // const config = {
    //   headers: { "Content-Type": "text/xml" }
    // };
    // this.axios
    //   .post(
    //     "https://secure-supply-xml.booking.com/hotels/xml/reservationssummary",
    //     xmlString,
    //     config
    //   )
    //   .then(response => {})
    //   .catch(error => {
    //     console.log(error);
    //   });
  }
  validNumber(evt) {
    var theEvent = evt || window.event;
    var key = "";
    // Handle paste
    if (theEvent.type === "paste") {
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  calendar() {
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php"
      )
      .then((response) => {
        if (response.data !== "") {
          this.cabins = [];
          var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
          var data = [];
          if (userLogin.some((x) => x.role === "user")) {
            data = response.data.filter((a) =>
              userLogin.some((c) => c.cabinId === a.id)
            );
          } else {
            data = response.data;
          }
          this.allCabins = data.map((cabin) => {
            return cabin;
          })
          this.mapedCabins = this.allCabins.filter(x => this.state.selectedCabin !== 'All' ? x.id === this.state.selectedCabin : true);
          this.mapedCabins.forEach((value, index) => {
            this.placeholderInvalidDates.push({
              cabin: value.cabinName,
              id: value.id,
              bookedId: -1,
              dates: [],
              months: [],
            });
            this.cabins.push({ name: value.cabinName, id: value.id, level: index, blockDays: value.blockDays ? JSON.parse(value.blockDays) : 0 });
          });

        }

        this.getBooked().then((value) => {

          this.bookedDates = value === "" ? [] : value;
          // console.log(this.bookedDates);
          this.bookedDates.forEach((value, i) => {
            if (
              value.paymentStatus === "Paid" ||
              value.paymentStatus === "Faktura"
            ) {
              var checkOut = new Date(value.checkOut.replace(",", ""));
              checkOut.setDate(checkOut.getDate());
              var dateArray = this.getDates(
                new Date(value.checkIn.replace(",", "")),
                checkOut
              );
              dateArray.forEach((dateArr) => {
                this.placeholderInvalidDates.map((info) => {
                  if (JSON.parse(value.cabinId).includes(info.id)) {

                    if (!info.dates.some((x) => x.dateArr === dateArr)) {
                      var d = new Date(dateArr);
                      if (!info.months.some(x => x.month === (d.getMonth() + 1))) {
                        info.months.push({ month: (d.getMonth() + 1), year: d.getFullYear() });
                      }
                      info.bookedId =
                        info.bookedId === -1 ? value.id : info.bookedId;
                      info.dates.push({
                        dateArr,
                        bookedId: value.id,
                        guest: value.firstName + " " + value.lastName,
                        isFromAirbnb: value.isFromAirbnb
                      });
                      return info;
                    }
                  }
                  return null;
                });

                var includes = true;

                for (var p = 0; p < this.placeholderInvalidDates.length; p++) {
                  if (
                    this.placeholderInvalidDates[p].dates.some(
                      // eslint-disable-next-line
                      (x) => x.dateArr === dateArr
                    ) &&
                    includes !== false
                  ) {
                    includes = true;
                  } else {
                    includes = false;
                  }
                }

                if (includes) {
                  dateArr = new Date(dateArr);
                  this.invalidDate.push(
                    dateArr.getFullYear() +
                    "/" +
                    (dateArr.getMonth() + 1) +
                    "/" +
                    dateArr.getDate()
                  );
                }
              });

            }
          });

          // console.log(this.placeholderInvalidDates)
          // console.log(this.cabins)
          // console.log(this.calendarLabel)
          this.setState({ updateState: this.state.updateState ? false : true });
        });
      });
  }


  arraysEqual(a, b) {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  checkValidCabin(a) {
    var valueTrue = true;
    a.forEach((valueA) => {
      if (
        this.cabins.some((x) => {
          return x === valueA;
        })
      ) {
      } else {
        valueTrue = false;
      }
    });
    return valueTrue;
  }

  updateBookings(e, id, define) {
    
    if (define === "cabinsById") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.cabinId = '["' + e.target.value + '"]';
          value.cabins = '["' + this.mapedCabins.find(x => x.id === e.target.value).cabinName + '"]';
          value.changed = true;

        }
        return value
      })

    }


    if (define === "actualCheckOut") {
      // this.guestsSelecedDate[i]["changed"] = true;
      var date = new Date(e.startDate._d);
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          let currentCabin = this.cabins.find(x=>x.id === JSON.parse(value.cabinId)[0]);
          value.actualCheckOut = this.days[date.getDay()] +
            " " +
            date.getDate() +
            ", " +
            this.monthNames[date.getMonth()] +
            " " +
            date.getFullYear();

            let checkOutDate = date;
            checkOutDate.setDate(date.getDate() + currentCabin.blockDays)
          value.checkOut = this.days[checkOutDate.getDay()] +
            " " +
            checkOutDate.getDate() +
            ", " +
            this.monthNames[checkOutDate.getMonth()] +
            " " +
            checkOutDate.getFullYear();
          value.changed = true;
        }
        return value;
      })
      this.setState({})
    }
    if (define === "actualCheckIn") {
      // this.guestsSelecedDate[i]["changed"] = true;
      var date = new Date(e.startDate._d);
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          let currentCabin = this.cabins.find(x=>x.id === JSON.parse(value.cabinId)[0]);
          value.actualCheckIn = this.days[date.getDay()] +
            " " +
            date.getDate() +
            ", " +
            this.monthNames[date.getMonth()] +
            " " +
            date.getFullYear();

            let checkInDate = date;
            checkInDate.setDate(date.getDate() - currentCabin.blockDays)
          value.checkIn = this.days[checkInDate.getDay()] +
            " " +
            checkInDate.getDate() +
            ", " +
            this.monthNames[checkInDate.getMonth()] +
            " " +
            checkInDate.getFullYear();

          value.changed = true;
        }
        return value;
      })
      this.setState({})
    }
    if (define === "checkIn") {
      // this.guestsSelecedDate[i]["changed"] = true;
      var date = new Date(e.startDate._d);
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.checkIn = this.days[date.getDay()] +
            " " +
            date.getDate() +
            ", " +
            this.monthNames[date.getMonth()] +
            " " +
            date.getFullYear();
          value.changed = true;
        }
        return value;
      })
      this.setState({})
    }
    if (define === "checkOut") {
      // this.guestsSelecedDate[i]["changed"] = true;
      var date = new Date(e.startDate._d);
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.checkOut = this.days[date.getDay()] +
            " " +
            date.getDate() +
            ", " +
            this.monthNames[date.getMonth()] +
            " " +
            date.getFullYear();
          value.changed = true;
        }
        return value;
      })
      this.setState({})
    }
    if (define === "guests") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.guests = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
    if (define === "firstName") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.firstName = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
    if (define === "lastName") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.lastName = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
    if (define === "costs") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.costs = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
    if (define === "comment") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.comment = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
    if (define === "email") {
      this.bookedDates = this.bookedDates.map((value, j) => {
        if (value.id === id) {
          value.email = e.target.value;
          value.changed = true;
        }
        return value;
      })
    }
  }

  getBooked() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBooking.php"
      )
      .then(function (response) {
        // handle success
        var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
        if (userLogin.some((x) => x.role === "user")) {

          response.data = response.data === "" ? [] : response.data;
          return response.data.filter((a) =>
            JSON.parse(a.cabinId).some((b) =>
              userLogin.some((c) => c.cabinId === b)
            )
          );
        } else {
          return response.data;
        }
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }
  deleteBooking(id) {
    var index = this.bookedDates.findIndex(x => x.id === id)
    var r = window.confirm(
      "Sure you wan't to delete item with payment id " +
      this.bookedDates[index].paymentId
    );
    if (this.bookedDates[index].new) {
      this.bookedDates.splice(index, 1);
      this.setState({});
      return;
    }
    if (r === true) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteBooking.php",
          this.bookedDates[index]
        )
        .then((response) => {
          if (response.data) {

            this.bookedDates.splice(index, 1);
          } else {
            alert("Deletion failed");
          }
        });
    }
  }
  sendPinInfo(i) {
    var r = window.confirm(
      "Sure you wan't to send pin info to " + this.guestsSelecedDate[i].email
    );
    if (r === true) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCabinSettings.php",
          { id: JSON.parse(this.guestsSelecedDate[i].cabinId) }
        )
        .then((response) => {
          this.guestsSelecedDate[i].info = response.data;
          this.guestsSelecedDate[i].companyName = this.settings.companyName;
          this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
          this.guestsSelecedDate[i].domain = this.settings.domain;
          this.guestsSelecedDate[i].protocol = this.settings.protocol;
          this.axios
            .post(
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/php/sendPin.php",
              this.guestsSelecedDate[i]
            )
            .then((response) => {
              if (response.data) {
                alert("Pin was successfully send!");
              } else {
                alert("Pin failed to send");
              }
            });
        });
    }
  }
  sendBooking(i) {
    var r = window.confirm(
      "Sure you wan't to send booking for this payment id " +
      this.guestsSelecedDate[i].paymentId
    );
    if (r === true) {
      this.guestsSelecedDate[i].companyName = this.settings.companyName;
      this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
      this.guestsSelecedDate[i].domain = this.settings.domain;
      this.guestsSelecedDate[i].protocol = this.settings.protocol;
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/sendBooking.php",
          this.guestsSelecedDate[i]
        )
        .then((response) => {
          if (response.data) {
            alert("Booking was successfully send!");
          } else {
            alert("Booking failed to send");
          }
        });
    }
  }

  refundBooking(i) {
    var r = window.confirm(
      "Sure you wan't to refund this payment id " +
      this.guestsSelecedDate[i].paymentId
    );
    if (r === true) {
      this.guestsSelecedDate[i].companyName = this.settings.companyName;
      this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
      this.guestsSelecedDate[i].domain = this.settings.domain;
      this.guestsSelecedDate[i].protocol = this.settings.protocol;
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/quickpay/cancel.php",
          this.guestsSelecedDate[i]
        )
        .then((response) => {
          if (response.data) {
            this.guestsSelecedDate[i]["changed"] = true;
            this.guestsSelecedDate[i].paymentStatus = "Refunded";
            this.save(true);
          } else {
            alert("Refund failed");
          }
        });
    }
  }
  compare(a, b) {
    if (a.checkIn >= b.checkIn) {
      return -1;
    }
    if (a.checkIn <= b.checkIn) {
      return 1;
    }
    return 0;
  }
  comparedateArr(a, b) {
    if (a.dateArr >= b.dateArr) {
      return -1;
    }
    if (a.dateArr <= b.dateArr) {
      return 1;
    }
    return 0;
  }
  hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }
  stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";

    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };
  save() {
    var filterBookedDates = this.bookedDates.filter(x => x.changed || x.new);
    // return

    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/updateBookings.php",
        filterBookedDates
      )
      .then((response) => {
        // console.log(response)
        if (response.data) {
          alert("Data saved successfully!");
        } else {
          alert("Saved failed");
        }
      });
  }
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }
  addBooking() {

    var actualDate = this.days[this.state.selectedDate.getDay()] +
      " " +
      this.state.selectedDate.getDate() +
      ", " +
      this.monthNames[this.state.selectedDate.getMonth()] +
      " " +
      this.state.selectedDate.getFullYear();

    var blockDays = this.mapedCabins[0].blockDays === "" ? 0 : JSON.parse(this.mapedCabins[0].blockDays);

    this.state.selectedDate.setDate(this.state.selectedDate.getDate() + blockDays);

    var newDate = this.days[this.state.selectedDate.getDay()] +
      " " +
      this.state.selectedDate.getDate() +
      ", " +
      this.monthNames[this.state.selectedDate.getMonth()] +
      " " +
      this.state.selectedDate.getFullYear();

    var newGuest = {
      id: uuidv4(),
      new: true,
      paymentStatus: "Faktura",
      checkOut: newDate,
      checkIn: newDate,
      actualCheckIn: actualDate,
      actualCheckOut: actualDate,
      cabinId: '["' + this.mapedCabins[0].id + '"]',
      cabins: '["' + this.mapedCabins[0].cabinName + '"]',
      blockDays: this.mapedCabins[0].blockDays ,
    }
    this.bookedDates.push(newGuest)
    this.setState({})
  }
  render() {
    this.guestsSelecedDate = [];
    this.bookedDates.forEach((value, i) => {
      if (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura") {
        var checkOut = Object.prototype.toString.call(value.checkOut) === "[object Date]" ? value.checkOut : new Date(value.checkOut.replace(",", ""));
        checkOut.setDate(checkOut.getDate());
        var dateArray = this.getDates(
          Object.prototype.toString.call(value.checkIn) === "[object Date]" ? value.checkIn : new Date(value.checkIn.replace(",", "")),
          checkOut
        );
        dateArray.forEach((date) => {
          if (date.valueOf() === new Date(this.state.selectedDate).valueOf()) {
            if (JSON.parse(value.cabinId).includes(this.state.selectedCabin) || this.state.selectedCabin === "All") {
              this.guestsSelecedDate.push(value);
            }
          }
        });
      }
    });
    var guests = null;
    if (this.guestsSelecedDate.length > 0) {
      guests = this.guestsSelecedDate.map((value, i) => {
        var actualCheckIn = Object.prototype.toString.call(value.actualCheckIn) === "[object Date]" ? value.actualCheckIn : new Date(value.actualCheckIn ? value.actualCheckIn.replace(",", "") : value.checkIn);
        actualCheckIn =
          actualCheckIn.getDate() +
          "/" +
          (actualCheckIn.getMonth() + 1) +
          "/" +
          actualCheckIn.getFullYear();

          
        var actualCheckOut = Object.prototype.toString.call(value.actualCheckOut) === "[object Date]" ? value.actualCheckOut : new Date(value.actualCheckOut ? value.actualCheckOut.replace(",", ""): value.checkOut);
        actualCheckOut =
          actualCheckOut.getDate() +
          "/" +
          (actualCheckOut.getMonth() + 1) +
          "/" +
          actualCheckOut.getFullYear();
        var checkOutDate = Object.prototype.toString.call(value.checkOut) === "[object Date]" ? value.checkOut : new Date(value.checkOut.replace(",", ""));
        var checkInDate = Object.prototype.toString.call(value.checkIn) === "[object Date]" ? value.checkIn : new Date(value.checkIn.replace(",", ""));
        var checkIn =
          checkInDate.getDate() +
          "/" +
          (checkInDate.getMonth() + 1) +
          "/" +
          checkInDate.getFullYear();
        var checkOut =
          checkOutDate.getDate() +
          "/" +
          (checkOutDate.getMonth() + 1) +
          "/" +
          checkOutDate.getFullYear();
        // var cabins = JSON.parse(value.cabins).map((element, i) => {
        //   var comma = "";
        //   if (JSON.parse(value.cabins).length > i + 1) {
        //     comma = ", ";
        //   }
        //   return element + comma;
        // });
        var selectCabins = this.mapedCabins.map((cvalue, i) => {

          return <option key={i} defaultValue={cvalue.id} value={cvalue.id}>{cvalue.cabinName}</option>
        })
        return (
          <tr className={value.isFromAirbnb === "1" ? 'disabled' : ''} key={uuidv4()}>

            <td>{value.reg_date}</td>
            <td>{value.paymentId}</td>
            <td>
              <select className="select" defaultValue={JSON.parse(value.cabinId)[0]} onChange={(e) => {
                this.updateBookings(e, value.id, "cabinsById");
              }}>
                {selectCabins}
              </select>

            </td>
            <td>
              <div>

                {checkIn}
              </div>
            </td>
            <td>
              <div>

                {checkOut}
              </div>
            </td>
            <td>
              <DatePicker
                singleDatePicker={true}
                autoApply={true}
                startDate={new Date(value.actualCheckIn.replace(",", ""))}
                locale={{ format: "D.M.YYYY" }}
                disabledDates={["02/25/2015"]}
                onApply={(e, p) => {
                  if (p.startDate._d.valueOf() > this.state.selectedDate.valueOf()) {
                    alert('Date is larger than selected Calendar date');
                    return;
                  }
                  this.updateBookings(p, value.id, "actualCheckIn");
                }}
              >
                {actualCheckIn}
              </DatePicker>
            </td>
            <td><DatePicker
              singleDatePicker={true}
              autoApply={true}
              startDate={new Date(value.actualCheckOut.replace(",", ""))}
              locale={{ format: "D.M.YYYY" }}
              onApply={(e, p) => {
                if (p.startDate._d.valueOf() < this.state.selectedDate.valueOf()) {
                  alert('Date is less than selected Calendar date');
                  return;
                }
                this.updateBookings(p, value.id, "actualCheckOut");
              }}
            >
              {actualCheckOut}
            </DatePicker></td>
            <td>
              <input
                onPaste={(e) => {
                  this.validNumber(e);
                }}
                onKeyPress={(e) => {
                  this.validNumber(e);
                }}
                onBlur={e => {
                  this.updateBookings(e, value.id, "guests");
                }}
                defaultValue={value.guests}
              >

              </input>
            </td>
            <td>
              <input
                onBlur={e => {
                  this.updateBookings(e, value.id, "firstName");
                }}
                defaultValue={value.firstName}
              ></input>
            </td>
            <td>
              <input
                onBlur={e => {
                  this.updateBookings(e, value.id, "lastName");
                }}
                defaultValue={value.lastName}
              ></input>
            </td>
            <td>
              <input
                onBlur={e => {
                  this.updateBookings(e, value.id, "email");
                }}
                defaultValue={value.email}
              ></input>
            </td>

            <td>
              <input
                onPaste={(e) => {
                  this.validNumber(e);
                }}
                onKeyPress={(e) => {
                  this.validNumber(e);
                }}
                onBlur={e => {
                  this.updateBookings(e, value.id, "costs");
                }}
                defaultValue={value.costs}
              ></input>
            </td>
            <td>
              <div>{value.paymentStatus}</div>
            </td>

            <td>
              <textarea
                onBlur={e => {
                  this.updateBookings(e, value.id, "comment");
                }}
                defaultValue={value.isFromAirbnb === "1" ? "airbnb" : value.comment}
              ></textarea>
            </td>
            <td>
              {/* <div
                className="action-btn"
                onClick={() => {
                  this.sendBooking(i);
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                <p>Send booking</p>
              </div> */}

              {/* <div
                className="action-btn"
                onClick={() => {
                  this.refundBooking(i);
                }}
              >
                <FontAwesomeIcon icon={faCreditCard} />
                <p>Refund</p>
              </div> */}
              <div
                className="action-btn"
                onClick={() => {
                  this.deleteBooking(value.id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                <p>Delete</p>
              </div>
              {/* <div
                className="action-btn"
                onClick={() => {
                  this.sendPinInfo(i);
                }}
              >
                <FontAwesomeIcon icon={faUnlockAlt} />
                <p>Send pin</p>
              </div> */}
            </td>
          </tr>
        );
      });
    }

    return (
      <content id="overview">
        <div className="wrap-content">
          <div className="front-page-content">
            <div className="headline">
              <h3>Overview  {settings.house ? 'Cabins' : ''}</h3>
              <select defaultValue={this.state.selectedCabin} onChange={(e) => { this.setState({ selectedCabin: e.target.value }, () => { this.calendar(); }) }}>
                <option>All</option>
                {this.allCabins.map((cvalue, i) => {

                  return <option key={i} defaultValue={cvalue.id} value={cvalue.id}>{cvalue.cabinName}</option>
                })}
              </select>
            </div>
            <div className="calendar">
              <div className="houses">
                {[0, 1, 2, 3, 4].map(() => {
                  return <div className="each-week">
                    {this.cabins.map((element) => {
                      return <div style={{
                        backgroundColor: this.ColorLuminance(
                          this.colorArray[element.id % 280],
                          -0.4
                        ),
                      }}>
                        <p>{element.name}</p>
                      </div>;
                    })}
                  </div>
                })}

              </div>
              <Calendar
                onClickDay={(e) => {
                  this.setState({ selectedDate: e });
                }}
                navigationLabel={(e) => {
                  this.calendarLabel = e.label;
                  return e.label;
                }}
                tileContent={(e) => {
                  const d = new Date(e.date);
                  var cabins = [];
                  this.placeholderInvalidDates.forEach((value) => {
                    var filterDates = value.dates.filter((x) => { return x.dateArr === d.valueOf() });
                    filterDates.forEach((date) => {
                      value.bookedId = date.bookedId;
                      value.guest = date.guest;
                      cabins.push(value);
                    });
                  });


                  cabins = cabins.map((maped) => {

                    var filterDates = maped.dates.filter((x) => { return x.bookedId === maped.bookedId });
                    filterDates.sort(this.comparedateArr)

                    var checkIn = this.bookedDates.find(
                      (x) => x.id === maped.bookedId
                    ).checkIn;

                    var checkOut = this.bookedDates.find(
                      (x) => x.id === maped.bookedId
                    ).checkOut;
                    checkOut = new Date(checkOut.replace(",", ""));
                    checkIn = new Date(checkIn.replace(",", ""));

                    maped.firstDate = d.valueOf() === filterDates[filterDates.length - 1].dateArr;
                    maped.lastDate = d.valueOf() === filterDates[0].dateArr;
                    maped.isFromAirbnb = filterDates[0].isFromAirbnb;
                    return maped;
                  });
                  cabins.sort(this.compare);

                  var houses = cabins.map((value, key) => {

                    return (
                      <div
                        key={key}
                        level={this.cabins.find(x => x.id === value.id).level}
                        style={{
                          backgroundColor: this.ColorLuminance(
                            this.colorArray[value.id % 280],
                            -0.4
                          ),
                        }}
                        className={(value.firstDate ? "firstDate " : "") + (value.lastDate ? "lastDate " : "") + "small-calendar-text some-booked"}
                      >
                        <MobileView>{value.isFromAirbnb === "1" ? 'AirBnb' : value.guest.length <= 1 ? 'blocked' : value.guest} </MobileView>
                        <BrowserView>
                          {value.isFromAirbnb === "1" ? 'AirBnb' : value.guest <= 1 ? 'blocked' : value.guest}
                        </BrowserView>
                      </div>
                    );
                  });

                  // var notValidCabins = [];
                  // this.placeholderInvalidDates.forEach((element) => {
                  //   element.months.forEach((x) => {

                  //     if (this.monthNames[x.month] + ' ' + x.year === this.calendarLabel) {

                  //       if(!notValidCabins.some(x=> x.id === element.id)){
                  //         notValidCabins.push({id: element.id, name:element.cabin})
                  //       }
                  //     }
                  //   })
                  // })
                  // console.log(notValidCabins)
                  // this.cabins = validCabins.map((element, index)=>{
                  //   element.level = index;
                  //   return element;
                  // })

                  Array.prototype.insert = function (index, item) {
                    this.splice(index, 0, item);
                  };
                  var lastIndex = 0;
                  if (houses.length > 0) {
                    lastIndex = houses[houses.length - 1].props.level;
                  }
                  var newLines = [];
                  for (var i = 0; i < this.cabins.length; i++) {
                    if ((newLines.length + houses.length) < this.cabins.length) {
                      newLines.push(<div label={this.cabins[i].id} className={"small-calendar-text some-booked available"}>
                        available
                      </div>)
                    }
                  }
                  houses.forEach((element) => {
                    newLines.insert(element.props.level, element);
                  })


                  // houses = newHouses;
                  return newLines;
                  // if (countSoldout > 0) {
                  //   return (
                  //     <div className="small-calendar-text some-booked">
                  //       <MobileView>{countSoldout}</MobileView>
                  //       <BrowserView>
                  //         {countSoldout}&nbsp;cabins&nbsp;booked
                  //       </BrowserView>
                  //     </div>
                  //   );
                  // } else {
                  //   return (
                  //     <div className="small-calendar-text">
                  //       <MobileView>{countSoldout}</MobileView>
                  //       <BrowserView>
                  //         {countSoldout}&nbsp;cabin&nbsp;booked
                  //       </BrowserView>
                  //     </div>
                  //   );
                  // }

                }}
              />
            </div>
            <div
              style={{
                display: this.state.selectedDate !== null ? "block" : "none",
              }}
              className="info"
            >
              <div className="wrap-table-header">
                <div>
                  <div
                    onClick={() => (
                      this.addBooking()
                    )}

                    className="addBooking">
                    <div>
                      <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                      Add booking
                    </div>
                  </div>
                  <div
                    onClick={() => (
                      this.save()
                    )}

                    className="save">
                    <div>
                      <p>Save</p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    this.setState({ selectedDate: null });
                  }}
                  className="close"
                >
                  <p>Close</p>
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </div>
              </div>
              <div className="wrap-table">
                <table>
                  <tbody>
                    <tr>
                      <th>Register date</th>
                      <th>Payment id</th>
                      <th>Cabins</th>
                      <th>Blocked from</th>
                      <th>Blocked to</th>
                      <th>Check in</th>
                      <th>Check out</th>
                      <th>Guests</th>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Email</th>

                      <th>Costs</th>
                      <th>Payment status</th>

                      <th>Comment</th>
                      <th>Action</th>
                    </tr>
                    {guests}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default Overview;
