import React, { Component } from "react";
import settings from "../../settings.json";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import ReactHtmlParser from "react-html-parser";
import {
  faUserFriends,
  faCheck,
  faCalendarCheck,
  faMinus,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-bootstrap-daterangepicker";
import "./availability.scss";
import translate from "../../translate.json";
import defaultImage from "../../assets/default-placeholder.png";

class Availability extends Component {
  invalidStartDates = [];
  invalidEndDates = [];
  placeholderInvalidEndDates = [];
  placeholderInvalidStartDates = [];
  checkInCheckOutArr = [];
  startDate = new Date();
  endDate = new Date();
  currentDate = new Date();
  maxGuests = 0;
  constructor(props) {
    super(props);
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.queryString = require("query-string");
    this.urlParameters = this.queryString.parse(this.props.location.search);
    this.bookedDates = [];
    this.invalidDate = [];
    this.invalidCabinDate = [];
    this.cabins = [];
    this.util = require("util");
    this.clientInfo = sessionStorage.getItem("clientInfo")
      ? JSON.parse(sessionStorage.getItem("clientInfo"))
      : { startDate: "", endDate: "", guests: 2 };
    var startDate = new Date(
      this.clientInfo.startDate !== ""
        ? this.clientInfo.startDate
        : this.urlParameters.startDate
    );
    var endDate = new Date(
      this.clientInfo.endDate !== ""
        ? this.clientInfo.endDate
        : this.urlParameters.endDate
    );
    this.state = {
      loading: false,
      fixedBookedBtn: React.createRef(),
      fixedTable: React.createRef(),
      bookBtn: false,
      table: false,
      selectedHouse: this.urlParameters.selectedHouse
        ? this.urlParameters.selectedHouse
        : null,
      roomId: this.urlParameters.roomId
        ?? null,
      cabins: [],
      currentDate:
        this.currentDate.getDate() +
        "/" +
        (this.currentDate.getMonth() + 1) +
        "/" +
        this.currentDate.getFullYear(),
      startDate: isNaN(startDate.getTime())
        ? ""
        : startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear(),
      startDateFormat: new Date(
        this.clientInfo.startDate !== ""
          ? this.clientInfo.startDate
          : this.urlParameters.startDate
      ),
      endDate: isNaN(endDate.getTime())
        ? ""
        : endDate.getDate() +
        "." +
        (endDate.getMonth() + 1) +
        "." +
        endDate.getFullYear(),
      endDateFormat: new Date(
        this.clientInfo.endDate !== ""
          ? this.clientInfo.endDate
          : this.urlParameters.endDate
      ),
      guests: this.clientInfo ? JSON.parse(this.clientInfo.guests) : 2,
      amountCabins: 0,
      checkAvailability: false,
      endDateNotSelected: true,
      startDateNotSelected: true,
      readyForBooking: false,
      minDate: isNaN(startDate.getTime()) ? new Date("1200/01/01") : startDate,
      daySelected: 0,
    };

    this.axios = require("axios");

    this.getCabins();
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  handleScroll = () => {
    var widthPerc = (window.innerWidth / 100) * 37.2;
    if (this.state.fixedBookedBtn.current !== null) {
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc <=
        window.pageYOffset &&
        this.state.bookBtn === false
      ) {
        this.setState({ bookBtn: true });
      }
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc >=
        window.pageYOffset &&
        this.state.bookBtn === true
      ) {
        this.setState({ bookBtn: false });
      }
    }
    var widthPer = (window.innerWidth / 100) * 1.8;
    if (this.state.fixedTable.current !== null) {
      if (
        this.state.fixedTable.current.offsetTop - widthPer <=
        window.pageYOffset &&
        this.state.table === false
      ) {
        this.setState({ table: true });
      }
      if (
        this.state.fixedTable.current.offsetTop - widthPer >=
        window.pageYOffset &&
        this.state.table === true
      ) {
        this.setState({ table: false });
      }
    }
  };
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    end = new Date(end);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  compareMinDate(a, b) {
    if (a.minDate > b.minDate) {
      return -1;
    }
    if (a.minDate < b.minDate) {
      return 1;
    }
    return 0;
  }
  compareAvailable(a, b) {
    if (a.available > b.available) {
      return -1;
    }
    if (a.available < b.available) {
      return 1;
    }
    return 0;
  }

  getCabins() {

    this.invalidStartDates = [];
    this.invalidEndDates = [];
    let beds24 = {
      endpoint: "getProperties"
    };
    if (settings.beds24 && this.state.selectedHouse && this.state.roomId) {
      beds24.propId = this.state.selectedHouse
      beds24.roomId = this.state.roomId
    }
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php", {
        beds24
      }
      )
      .then((response) => {

        if (settings.beds24) {
          const filterResponse = response.data;
          response.data = []

          filterResponse.forEach((elm) => {
            elm.mainImage = defaultImage;
            if (elm.gallery && elm.gallery.length > 0) {
              elm.mainImage = elm.gallery[0].url;
            }
            if (this.state.roomId) {
              elm.roomTypes = elm.roomTypes.filter(x => x.roomId === this.state.roomId);
            }
            elm.roomTypes.forEach((room) => {
              const roomContent = elm.rooms.find(x => x.roomId === room.roomId);
              const description = roomContent?.roomDescription.length > 150 ? roomContent?.roomDescription.slice(0, 150).split(".")[0] + "..." : roomContent?.roomDescription;
              elm.maxGuests = room.maxPeople;
              elm.name = roomContent?.name;
              elm.roomId = room.roomId;
              elm.textShort = description;
              elm.textShortFo = description;
              if (roomContent?.images.length > 0) {
                elm.mainImage1 = roomContent.images[0];
              }
              if (elm.mainImage1 && elm.mainImage.includes('/static/media/default-placeholder')) {
                elm.mainImage = elm.mainImage1;
              }
              elm.maxGuests = "0"
              elm.minDates = "2"
              elm.roomTypes.forEach((elme) => {
                if (elme.maxPeople > elm.maxGuests) {
                  elm.maxGuests = elme.maxPeople;
                }

              })
              response.data.push(JSON.parse(JSON.stringify(elm)))
            })
          })
        }
        const mapedCabins = response.data.filter(x => x.rooms.length > 0).map((cabin, i) => {
          // if (settings.beds24) {


          //   let room = cabin.rooms.find(x => x.roomId === this.state.roomId)
          //   if (room === undefined) {
          //     room = cabin.rooms[0];
          //   }
          //   cabin.textShort = room?.name
          //   cabin.textShortFo = room?.name
          //   cabin.textLong = room?.roomDescription
          //   cabin.textLongFo = room?.roomDescription
          //   cabin.mainImage = defaultImage;
          //   if (cabin.gallery && cabin.gallery.length > 0) {
          //     cabin.mainImage = cabin.gallery[0]?.url;
          //   }

          //   if (room.images.length > 0) {
          //     cabin.gallery = room.images;
          //     if (cabin.mainImage.includes('/static/media/default-placeholder')) {
          //       cabin.mainImage = cabin.gallery[0];
          //     }
          //   }
          //   cabin.maxGuests = "0"
          //   cabin.minDates = "2"
          //   cabin.roomTypes.forEach((elme) => {
          //     if (elme.maxPeople > cabin.maxGuests) {
          //       cabin.maxGuests = elme.maxPeople;
          //     }

          //   })
          // }
          if (this.state.selectedHouse !== null) {
            if (this.state.selectedHouse === cabin.id) {
              this.maxGuests += JSON.parse(cabin.maxGuests);
              this.invalidStartDates.push({
                cabin: cabin.cabinName,
                roomId: this.state.roomId ?? "",
                dates: [],
              });
              this.invalidEndDates.push({
                cabin: cabin.cabinName,
                roomId: this.state.roomId ?? "",
                dates: [],
              });

              this.checkInCheckOutArr.push({
                cabin: cabin.cabinName,
                checkInTime: cabin.checkInTime ?? "",
                checkOutTime: cabin.checkOutTime ?? "",
                roomId: this.state.roomId ?? "",
                checkIn: [],
                checkOut: [],
                both: [],
                onlyCheckIn: [],
                onlyCheckOut: [],
              });
              let img =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/medium_" +
                cabin.mainImage;
              if (settings.beds24) {
                img = cabin.mainImage;
              }
              this.cabins.push({
                id: cabin.id,
                name: cabin.cabinName,
                text: cabin.textShort,
                available: true,
                selected: false,
                checkInTime: cabin.checkInTime,
                checkOutTime: cabin.checkOutTime,
                onRequest: cabin.onRequest,
                cleaning: cabin.cleaning,
                img: img,
                specialComments: cabin.specialComments,
                maxGuests: cabin.maxGuests,
                minDate: cabin.minDates,
                price: cabin.price,
                roomId: cabin.roomId,
                invalidDates: [],
                dates: [],
              });
            }
          } else {
            cabin.maxGuests = cabin.maxGuests === "" ? 0 : cabin.maxGuests;
            this.maxGuests += JSON.parse(cabin.maxGuests);
            this.invalidStartDates.push({
              cabin: cabin.cabinName,
              dates: [],
            });
            this.invalidEndDates.push({
              cabin: cabin.cabinName,
              dates: [],
            });

            this.checkInCheckOutArr.push({
              cabin: cabin.cabinName,
              checkIn: [],
              checkOut: [],
              both: [],
              onlyCheckIn: [],
              onlyCheckOut: [],
            });
            let img =
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/uploads/medium_" +
              cabin.mainImage;
            if (settings.beds24) {
              img = cabin.mainImage;
            }
            this.cabins.push({
              id: cabin.id,
              name: cabin.cabinName,
              text: cabin.textShort,
              available: true,
              selected: false,
              cleaning: cabin.cleaning,
              onRequest: cabin.onRequest,
              img: img,
              specialComments: cabin.specialComments,
              maxGuests: cabin.maxGuests,
              minDate: cabin.minDates,
              price: cabin.price,
              roomId: cabin.roomId,
              invalidDates: [],
              dates: [],
            });
          }

          return cabin;
        });

        this.cabins.sort(this.compareMinDate);
        this.cabins.sort(this.compareAvailable);
        for (var i = this.cabins.length; i >= 1; i--) {
          if (this.state.guests <= i * 4) {
            this.setState({ amountCabins: i });
          }
        }
        var placeholderInvalidDates = [];
        mapedCabins.forEach((value) => {
          placeholderInvalidDates.push({ cabin: value.cabinName, dates: [] });
        });

        this.setState({ mapedCabins }, () => {
          this.getBooked().then((value) => {
            this.bookedDates = value === "" ? [""] : value;
            this.bookedDates = this.bookedDates.filter(x => x.paymentStatus !== "On request")
            this.bookedDates.forEach((value, i) => {
              // if (settings.beds24) {
              //   value.checkIn = value.firstNight
              //   value.checkOut = value.lastNight
              //   value.cabins = '["Beds24-Stuff"]'
              // }
              if (value !== "") {
                var stringOrId = false;
                // if (!settings.beds24) {
                if (value.cabinId !== "") {
                  stringOrId = JSON.parse(value.cabinId).includes(
                    this.state.selectedHouse
                  );
                } else {
                  stringOrId = JSON.parse(value.cabins).includes(
                    this.cabins.name
                  );
                }
                // }
                if (this.state.selectedHouse !== null || stringOrId) {
                  if (value.paymentStatus === "On request") {
                    var d = new Date();

                    var hours = Math.abs(d - new Date(value.reg_date)) / 36e5;
                    if (hours < 24) {
                      value.onHold = true
                    }

                  }
                  if (
                    value.paymentStatus === "Paid" ||
                    value.paymentStatus === "Faktura" ||
                    value.onHold ||
                    value.status > 0
                  ) {
                    var checkIn = new Date(value.checkIn.replace(",", ""));

                    checkIn.setDate(checkIn.getDate());

                    checkIn.setHours(0, 0, 0, 0);
                    var checkOut = new Date(value.checkOut.replace(",", ""));

                    checkOut.setDate(checkOut.getDate());

                    checkOut.setHours(0, 0, 0, 0);

                    var dateArray = this.getDates(checkIn, checkOut);
                    this.checkInCheckOutArr.map((info) => {

                      if (JSON.parse(value.cabins).includes(info.cabin) || value.roomId === info.roomId) {
                        if (!info.checkIn.includes(checkIn.valueOf())) {
                          info.checkIn.push(checkIn.valueOf());
                        }
                        if (!info.checkOut.includes(checkOut.valueOf())) {
                          info.checkOut.push(checkOut.valueOf());
                        }
                      }
                      return info;
                    });

                    dateArray.forEach((dateArr) => {
                      this.invalidStartDates.map((info) => {

                        if (JSON.parse(value.cabins).includes(info.cabin) || value.roomId === info.roomId) {
                          if (!info.dates.includes(dateArr)) {
                            if (dateArr !== checkIn.valueOf()) {
                              if (dateArr !== checkOut.valueOf()) {
                                return info.dates.push(dateArr);
                              }
                            }
                          }
                        }
                        return null;
                      });
                      this.invalidEndDates.map((info) => {
                        if (JSON.parse(value.cabins).includes(info.cabin) || value.roomId === info.roomId) {
                          if (!info.dates.includes(dateArr)) {
                            if (dateArr !== checkIn.valueOf()) {
                              if (dateArr !== checkOut.valueOf()) {
                                return info.dates.push(dateArr);
                              }
                            }
                          }
                        }
                        return null;
                      });
                    })
                  }
                }
              }
            });
            if (this.placeholderInvalidStartDates.length === 0) {
              this.placeholderInvalidStartDates = this.invalidStartDates;

            }
            if (this.placeholderInvalidEndDates.length === 0) {
              this.placeholderInvalidEndDates = this.invalidEndDates;
            }

            this.loopeDate = new Date();
            this.checkCabinAvailability();
            if (
              this.clientInfo.startDate !== "" &&
              this.clientInfo.endDate !== ""
            ) {
              this.checkAvailability();
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBooked() {
    let beds24;
    if (settings.beds24) {
      beds24 = {
        roomId: this.state.roomId,
        propId: this.state.selectedHouse
      }
    }
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBooking.php",
        {
          beds24
        }
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }
  handleEvent = (event, picker) => {
    const startDate = new Date(picker.startDate._d);
    const endDate = new Date(picker.endDate._d);

    this.setState(
      {
        startDate:
          startDate.getDate() +
          "." +
          (startDate.getMonth() + 1) +
          "." +
          startDate.getFullYear(),
        startDateFormat: startDate,
        minDate: startDate,
        endDate:
          endDate.getDate() +
          "." +
          (endDate.getMonth() + 1) +
          "." +
          endDate.getFullYear(),
        endDateFormat: endDate,
      },
      this.checkAvailability
    );
  };

  handleStartDate = (event, picker) => {
    this.setState({ readyForBooking: false, missingCabin: false });

    this.setState({
      endDate: "",
      endDateFormat: "",
      minDate: new Date("1200/01/01"),
    });
    var error = false;
    if (this.state.endDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      this.days = Math.round(
        Math.abs(
          (new Date(picker.startDate._d) - this.state.endDateFormat) / oneDay
        )
      );

      this.loopDate = new Date(picker.startDate._d);

      for (var i = 0; i <= this.days; i++) {
        if (this.loopDate <= this.state.endDateFormat) {
          if (
            this.invalidDate.includes(
              this.loopDate.getFullYear() +
              "/" +
              (this.loopDate.getMonth() + 1) +
              "/" +
              this.loopDate.getDate()
            )
          ) {
            error = true;
          }
        }

        this.loopDate.setDate(this.loopDate.getDate() + 1);
      }
    }
    if (error) {
      this.setState({
        occupied: "Some or all dates in your selected range are sold out",
      });
    } else {
      this.setState({
        occupied: false,
      });
    }
    this.setState({
      checkAvailability: false,
      readyForBooking: false,
    });
    var startDate = new Date(picker.startDate._d);
    this.setState({ startDateNotSelected: true });
    this.setState({
      startDate:
        startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear(),
      startDateFormat: startDate,
      minDate: startDate,
    });
    this.clientInfo.startDate =
      startDate.getFullYear() +
      "/" +
      (startDate.getMonth() + 1) +
      "/" +
      startDate.getDate();
  };
  handleEndDate = (event, picker) => {
    this.setState({ readyForBooking: false, missingCabin: false });
    var error = false;
    if (this.state.startDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      this.days = Math.round(
        Math.abs(
          (new Date(picker.endDate._d) - this.state.startDateFormat) / oneDay
        )
      );

      this.loopDate = new Date(picker.endDate._d);
      for (var i = 0; i <= this.days; i++) {
        if (this.loopDate >= this.state.startDateFormat) {
          if (
            this.invalidDate.includes(
              this.loopDate.getFullYear() +
              "/" +
              (this.loopDate.getMonth() + 1) +
              "/" +
              this.loopDate.getDate()
            )
          ) {
            error = true;
          }
        }

        this.loopDate.setDate(this.loopDate.getDate() - 1);
      }
    }
    if (error) {
      this.setState({
        occupied: "Some or all dates in your selected range are sold out",
      });
    } else {
      this.setState({
        occupied: false,
      });
    }
    this.setState({
      checkAvailability: false,
      readyForBooking: false,
    });
    var endDate = new Date(picker.endDate._d);
    this.setState({ endDateNotSelected: true });
    this.setState({
      endDate:
        endDate.getDate() +
        "." +
        (endDate.getMonth() + 1) +
        "." +
        endDate.getFullYear(),
      endDateFormat: endDate,
    });
    this.clientInfo.endDate =
      endDate.getFullYear() +
      "/" +
      (endDate.getMonth() + 1) +
      "/" +
      endDate.getDate();
  };

  addPeople = (amount) => {
    this.setState({ readyForBooking: false, missingCabin: false, checkAvailability: false });

    var cabins = this.state.cabins.map((cabin) => {
      cabin.selected = false;
      return cabin;
    });
    this.setState({ cabins: cabins });
    if (
      this.state.guests + amount <= this.maxGuests &&
      this.state.guests + amount >= 1
    ) {
      this.setState({ guests: this.state.guests + amount });
      this.clientInfo.guests = this.state.guests + amount;
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));

      // for (var i = this.cabins.length; i >= 1; i--) {
      //   if (amount + this.state.guests <= i * 4) {
      //     this.setState({ amountCabins: i });
      //   }
      // }
    }
  };

  checkAvailability = () => {
    this.setState({ loading: true, checkAvailability: false });
    if (settings.beds24) {

      const beds24 = {
        checkIn: this.state.startDate.split('.').reverse().map(x => x.length > 1 ? x : "0" + x).join(""),
        checkOut: this.state.endDate.split('.').reverse().map(x => x.length > 1 ? x : "0" + x).join(""),
        propId: this.state.selectedHouse,
        adult: JSON.stringify(this.state.guests),
        numChild: '0',
        endpoint: "getProperties",
        ignoreAvail: true,
        roomId: this.state.roomId ?? ""
      }
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCabins.php", {
          beds24
        }
        )
        .then((response) => {
          if (settings.beds24) {
            const filterResponse = response.data;
            response.data = []

            filterResponse.forEach((elm) => {
              if (elm.gallery && elm.gallery.length > 0) {
                elm.mainImage = elm.gallery[0].url;
              }
              elm.roomTypes.forEach((room) => {
                const roomContent = elm.rooms.find(x => x.roomId === room.roomId);
                const description = roomContent?.roomDescription.length > 150 ? roomContent?.roomDescription.slice(0, 150).split(".")[0] + "..." : roomContent?.roomDescription;

                elm.maxGuests = room.maxPeople;
                elm.name = roomContent?.name;
                elm.roomId = room.roomId;
                elm.textShort = description;
                elm.textShortFo = description;
                if (roomContent?.images.length > 0) {
                  elm.mainImage1 = roomContent.images[0];
                }
                if (elm.mainImage1 && elm.mainImage.includes('/static/media/default-placeholder')) {
                  elm.mainImage = elm.mainImage1;
                }
                elm.maxGuests = "0"
                elm.minDates = "2"
                elm.roomTypes.forEach((elme) => {
                  if (elme.maxPeople > elm.maxGuests) {
                    elm.maxGuests = elme.maxPeople;
                  }

                })
                response.data.push(JSON.parse(JSON.stringify(elm)))
              })
            })
          }
          // console.log(response.data);
          // var daySelected =
          //   this.state.endDateFormat !== "" && this.state.startDateFormat !== ""
          //     ? this.getDates(
          //       this.state.startDateFormat.toUTCString(),
          //       this.state.endDateFormat.toUTCString()
          //     )
          //     : "";
          var cabinArray = [];
          // if (settings.beds24) {
          //   const filterResponse = response.data;
          //   response.data = []

          //   filterResponse.forEach((elm) => {
          //     if (elm.gallery && elm.gallery.length > 0) {

          //       elm.mainImage = elm.gallery[0].url;
          //     }
          //     elm.rooms.forEach((room) => {
          //       elm.maxGuests = room.maxPeople;
          //       elm.name = room.name;
          //       elm.roomId = room.roomId;
          //       elm.textShort = elm.rooms.find(x => x.roomId === room.roomId).name;
          //       elm.textShortFo = elm.rooms.find(x => x.roomId === room.roomId).name;
          //       response.data.push(JSON.parse(JSON.stringify(elm)))
          //     })
          //   })
          // }
          const mapedCabins = response.data.map((cabin, i) => {
            if (settings.beds24) {
              let room = cabin.rooms.find(x => x.roomId === this.state.roomId)
              // if (room === undefined) {
              //   room = cabin.rooms[0]
              // }
              cabin.textShort = room?.name
              cabin.textShortFo = room?.name
              cabin.textLong = room?.roomDescription
              cabin.textLongFo = room?.roomDescription
              cabin.mainImage = defaultImage;
              if (cabin.gallery && cabin.gallery.length > 0) {
                cabin.mainImage = cabin.gallery[0]?.url;
              }

              if (room?.images.length > 0) {
                cabin.gallery = room.images;
                if (cabin.mainImage.includes('/static/media/default-placeholder')) {
                  cabin.mainImage = cabin.gallery[0];
                }
              }
              cabin.cleaning = room?.cleaningFee ?? "500";
              cabin.maxGuests = "0"
              cabin.minDates = "0"
              cabin.roomTypes.forEach((elme) => {
                if (elme.maxPeople > cabin.maxGuests) {
                  cabin.maxGuests = elme.maxPeople;
                }
                if (elme.minStay >= cabin.minDates) {
                  cabin.minDates = elme.minStay === "0" ? 1 : elme.minStay;
                }
              })
            }
            return cabin;
          })
          mapedCabins.filter(x => x.rooms.length > 0).forEach((cabin) => {

            let available = false;
            let price = "";
            let warn = "";
            let roomsavail = -1;
            cabin.rooms.filter(x => x.roomId === cabin.roomId).forEach((room) => {
              // console.log(room)
              warn = room?.price?.warn;
              if (warn === "" && !room.availability.error && (room.availability[cabin.roomId].roomsavail > 0 && room.availability[cabin.roomId].roomsavail < 3)) {
                available = true;
              }
              roomsavail = room.availability[cabin.roomId].roomsavail;
              price = room?.price?.roomprice ?? "Not available";
              cabin.cabinName = room.name !== "" ? room.name : cabin.cabinName;
            })
            cabinArray.push({
              id: cabin.id,
              name: cabin.cabinName,
              roomId: cabin.roomId,
              minDate: cabin.minDates,
              available,
              roomsavail,
              selected: false,
              onRequest: false,
              price: price,
              cleaning: cabin.cleaning,
              specialComments: cabin.specialComments,
              maxGuests: cabin.maxGuests,
              warn: warn,
            });
          });
          var booked = true;
          var index = 0;
          if (this.bookedDates[0].length > 0) {
          }
          cabinArray = cabinArray.sort((a, b) => Number(b.available) - Number(a.available));
          this.setState({ cabins: cabinArray }, () => {
            this.scrollToFu();
          });

          if (
            this.state.startDate === "" ||
            this.state.startDate === null ||
            this.state.startDate === undefined
          ) {
            this.setState({ startDateNotSelected: false });
          }

          if (
            this.state.endDate === "" ||
            this.state.endDate === null ||
            this.state.endDate === undefined
          ) {
            this.setState({ endDateNotSelected: false });
          }
          if (this.state.startDate && this.state.endDate) {
            this.setState({ checkAvailability: true, loading: false });
          }
        }).catch((error) => {
          console.log(error)
        })
      return;
    }
    var daySelected =
      this.state.endDateFormat !== "" && this.state.startDateFormat !== ""
        ? this.getDates(
          this.state.startDateFormat.toUTCString(),
          this.state.endDateFormat.toUTCString()
        )
        : "";
    var cabinArray = [];
    this.cabins.forEach((cabin) => {
      let available = true;
      let price = "0";
      let warn = "";

      price = cabin.price
      available = daySelected.length - 1 >= cabin.minDate ? true : false;

      cabinArray.push({
        warn,
        id: cabin.id,
        name: cabin.cabinName,
        minDate: cabin.minDate,
        available,
        price,
        selected: false,
        onRequest: cabin.onRequest,
        cleaning: cabin.cleaning,
        specialComments: cabin.specialComments,
        maxGuests: cabin.maxGuests,
        minDays: daySelected.length - 1 >= cabin.minDate ? true : false,
      });
    });
    var booked = true;
    var index = 0;
    if (this.bookedDates[0].length > 0) {
    }

    this.bookedDates.forEach((value, i) => {
      if (value !== "") {
        var checkOut = new Date(value.checkOut.replace(",", ""));
        var checkIn = new Date(value.checkIn.replace(",", ""));
        if (this.getDates(checkIn, checkOut).length === 2) {
          checkOut.setDate(checkOut.getDate() - 1);
        } else {
          checkOut.setDate(checkOut.getDate() - 1);
          checkIn.setDate(checkIn.getDate() + 1);
        }
        var checkSelected = this.getDates(checkIn, checkOut);
        checkSelected.forEach((dateValue) => {
          if (
            daySelected.includes(dateValue) &&
            (value.paymentStatus === "Paid" ||
              value.paymentStatus === "Faktura")
          ) {
            booked = false;
            if (value.cabinId === "[null]" || value.cabinId === "") {
              JSON.parse(value.cabins).forEach((name) => {
                index = cabinArray.findIndex((x) => x.name === name);
                if (index !== -1) {
                  cabinArray[index].available = booked;
                }
              });
            } else {
              JSON.parse(value.cabinId).forEach((id) => {
                index = cabinArray.findIndex((x) => x.id === id);
                if (index !== -1) {
                  cabinArray[index].available = booked;
                }
              });
            }
          }
        });
      }

      this.setState({ cabins: cabinArray }, () => {
        this.scrollToFu();
      });
    });

    if (
      this.state.startDate === "" ||
      this.state.startDate === null ||
      this.state.startDate === undefined
    ) {
      this.setState({ startDateNotSelected: false });
    }

    if (
      this.state.endDate === "" ||
      this.state.endDate === null ||
      this.state.endDate === undefined
    ) {
      this.setState({ endDateNotSelected: false });
    }
    if (this.state.startDate && this.state.endDate) {
      this.setState({ checkAvailability: true });
    }
  };

  selectCabin(index) {
    var cabins = this.state.cabins.map((value, i) => {
      if (index === i) {
        value.selected
          ? (value.selected = false)
          : this.state.readyForBooking === false
            ? (value.selected = true)
            : (value.selected = false);
      }
      return value;
    });
    this.setState({ cabins: cabins }, () => {
      var state = {};
      for (var key in this.state) {
        if (key !== "fixedBookedBtn") {
          if (key !== "fixedTable") {
            state[key] = this.state[key];
          }
        }
      }
      sessionStorage.setItem("currentState", JSON.stringify(state));
    });
    var selectedGuestsAmount = 0;
    cabins.forEach((value, i) => {
      if (value.selected) {
        selectedGuestsAmount =
          selectedGuestsAmount + JSON.parse(value.maxGuests);
      }
    });

    if (selectedGuestsAmount !== 0) {
      this.setState({ missingCabin: true });
    } else {
      this.setState({ missingCabin: false });
    }
    if (this.state.guests <= selectedGuestsAmount) {
      this.setState({ readyForBooking: true });
    } else {
      this.setState({ readyForBooking: false });
    }
  }
  scrollToFu = () => {
    var widthPerc = window.innerWidth;

    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  checkCabinAvailability() {
    this.bookedDates.forEach((value, i) => {
      if (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura") {
        var checkIn = new Date(value.checkIn.replace(",", ""));
        checkIn.setDate(checkIn.getDate());
        var checkOut = new Date(value.checkOut.replace(",", ""));
        checkOut.setDate(checkOut.getDate() - 1);
        this.dateArray = this.getDates(checkIn, checkOut);
        this.dateArray.forEach((dateArr) => {
          this.cabins.map((info) => {
            if (JSON.parse(value.cabins).includes(info.name)) {
              if (!info.dates.includes(dateArr)) {
                return info.dates.push(dateArr);
              }
            }
            return null;
          });

          const newDateArr = new Date(dateArr);
          this.cabins.forEach((cabin) => {
            if (cabin.dates.includes(dateArr)) {
              if (
                cabin.invalidDates.includes(
                  newDateArr.getFullYear() +
                  "/" +
                  (newDateArr.getMonth() + 1) +
                  "/" +
                  newDateArr.getDate()
                ) === false
              ) {
                cabin.invalidDates.push(
                  newDateArr.getFullYear() +
                  "/" +
                  (newDateArr.getMonth() + 1) +
                  "/" +
                  newDateArr.getDate()
                );
              }
            }
          });
        });
      }
    });
  }
  checkAvailabiltyStartDate(d, customDate) {
    var invalidStartDates = [];
    invalidStartDates = this.placeholderInvalidStartDates;
    if (customDate) {
      for (let [index, element] of this.checkInCheckOutArr.entries()) {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidStartDates[index].dates.includes(d.valueOf())) {
              invalidStartDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          return "checkIn";
        }
        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          return "checkOut";
        }
        return false;
      }
    } else {
      this.checkInCheckOutArr.map((element, index) => {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidStartDates[index].dates.includes(d.valueOf())) {
              invalidStartDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          invalidStartDates[index].dates.push(d.valueOf());
        }
        return element;
      });

      return invalidStartDates.every((x) => {
        return x.dates.includes(d.valueOf());
      });
    }
  }
  checkAvailabiltyEndDate(d, customDate) {
    var invalidEndDates = [];
    invalidEndDates = this.placeholderInvalidEndDates;
    if (customDate) {
      for (let [index, element] of this.checkInCheckOutArr.entries()) {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidEndDates[index].dates.includes(d.valueOf())) {
              invalidEndDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          return "checkIn";
        }
        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          return "checkOut";
        }
        return false;
      }
    } else {
      this.checkInCheckOutArr.map((element, index) => {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidEndDates[index].dates.includes(d.valueOf())) {
              invalidEndDates[index].dates.push(d.valueOf());
            }
          }
        }

        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          invalidEndDates[index].dates.push(d.valueOf());
        }
        return element;
      });

      return invalidEndDates.every((x) => {
        return x.dates.includes(d.valueOf());
      });
    }
  }
  render() {


    var cabins = this.cabins.map((val) => {
      if (this.state.cabins.length > 0) {
        let findex = this.state.cabins.findIndex(x => x.roomId === val.roomId)
        if (findex !== -1) {
          val.available = this.state.cabins[findex].available
          val.name = this.state.cabins[findex].name
        } else {
          // // val.price = "";
          val.available = false;
          // if (val.maxGuests < this.state.guests) {
          //   val.price = "Max " + val.maxGuests + " guests"
          // }
        }
      }
      return val;
    }).sort((a, b) => Number(b.available) - Number(a.available))
      .filter(x => { return (x?.minDate ? JSON.parse(x?.minDate) > 0 : true)})
      .map((cabin, i) => {
        var availableBtn = this.state.cabins.map((value, i) => {
          return value.roomId === cabin.roomId ? (
            <div key={i} className="select-cabin">
              <h2 className="cabin-price">
                {settings.beds24 ? value.warn === "" ? "DKK " + value.price + ",-" : value.warn : value.minDate > 0
                  ? value.available
                    ? "DKK " + value.price + ",-"
                    : translate.soldOut[settings.lang]
                  : translate.notAvail[settings.lang]}
              </h2>
              <div>
                <div className="p-div">
                  {settings.beds24 ? "" : value.minDate > 0 ? (
                    value.minDays ? (
                      value.available ? (
                        translate.prNight[settings.lang] +
                        " / " +
                        translate.max[settings.lang] +
                        value.maxGuests +
                        " " +
                        translate.guests[settings.lang]
                      ) : (
                        <DatePicker
                          startDate={this.state.startDate}
                          applyClass="off"
                          autoApply={true}
                          locale={{ format: "D.M.YYYY" }}
                          isInvalidDate={(date) => {
                            const d = new Date(date._d);
                            if (
                              cabin.invalidDates.includes(
                                d.getFullYear() +
                                "/" +
                                (d.getMonth() + 1) +
                                "/" +
                                d.getDate()
                              ) ||
                              cabin.minDate === "0"
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          onApply={this.handleEvent}
                        >
                          <FontAwesomeIcon icon={faCalendarCheck} />
                          {/* {translate.checkOuther[settings.lang]} */}
                        </DatePicker>
                      )
                    ) : (
                      translate.minOff[settings.lang] +
                      " " +
                      value.minDate +
                      (value.minDate > 1
                        ? " " + translate.days[settings.lang]
                        : " " + translate.day[settings.lang])
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div
                  onClick={() => {
                    value.available && cabin.minDate > 0
                      ? this.selectCabin(i)
                      : this.selectCabin(-1);
                  }}
                  className={
                    value.available && cabin.minDate > 0
                      ? value.selected
                        ? "all-btn selected-btn"
                        : "all-btn"
                      : "all-btn disable-btn"
                  }
                >
                  <div>
                    {value.selected ? (
                      <FontAwesomeIcon
                        className={this.state.startDateNotSelected ? "" : "missing"}
                        icon={faCheck}
                      />
                    ) : (
                      ""
                    )}{" "}
                    {value.selected
                      ? translate.deselect[settings.lang]
                      : translate.select[settings.lang]}
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        });
        return (
          <div key={i} className="cabin">
            <div className="cabin-image">
              {cabin.onRequest === "1" ? (
                <div className="on-request">
                  <p>{translate.requestBook[settings.lang]}</p>
                </div>
              ) : (
                ""
              )}
              <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultImage;
              }}
                alt="" src={cabin.img} />
            </div>
            <div className="cabin-info">
              <h2>{cabin.name}</h2>
              {/* {cabin.text.length > 100 ? cabin.text.slice(0, 100) + "..." : cabin.text} */}
              {ReactHtmlParser(cabin.text)}
              <Link className="cabin-link" key={i} to={"cabins?id=" + cabin.id + "&roomId=" + cabin.roomId}>
                Read More
              </Link>
            </div>
            {availableBtn}
          </div>
        );
      });

    return (
      <content id="availability">
        <div className="headline">
          <h1>{translate.beginAd[settings.lang]} …</h1>
        </div>
        <div
          ref={this.state.fixedTable}
          className={this.state.table ? "table" : "table"}
        >

          <div className={this.state.table ? "table-fixed" : ""}>
            <div className="available-shadow"></div>
            <div className="input-headline">
              <div>{translate.checkIn[settings.lang]}</div>
              <div>{translate.checkOut[settings.lang]}</div>
              <div>{translate.childrenUnder[settings.lang]}</div>
              <div></div>
            </div>
            <div className="input">
              <div className="th">
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
                <FontAwesomeIcon
                  className={this.state.startDateNotSelected ? "" : "missing"}
                  icon={faCalendarAlt}
                />
                <DatePicker
                  className="startDate"
                  singleDatePicker={true}
                  autoApply={true}
                  minDate={this.state.currentDate}
                  locale={{ format: "D.M.YYYY" }}
                  disabledDates={[this.state.currentDate]}
                  isInvalidDate={(date) => {
                    const d = new Date(date._d);

                    if (this.checkAvailabiltyStartDate(d, false)) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  onApply={this.handleStartDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.startDate.length > 0
                        ? this.state.startDate
                        : ""
                    }
                  />
                </DatePicker>
              </div>
              <div className="th">
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
                <FontAwesomeIcon
                  className={this.state.endDateNotSelected ? "" : "missing"}
                  icon={faCalendarAlt}
                />
                <DatePicker
                  autoApply={true}
                  singleDatePicker={true}
                  locale={{ format: "D.M.YYYY" }}
                  minDate={moment(this.state.minDate)}
                  startDate={
                    this.state.startDate !== ""
                      ? this.state.startDate
                      : this.currentDate
                  }
                  isInvalidDate={(date) => {
                    const d = new Date(date._d);

                    if (this.checkAvailabiltyEndDate(d, false)) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  onApply={this.handleEndDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.endDate.length > 0 ? this.state.endDate : ""
                    }
                  />
                </DatePicker>
              </div>
              <div className="th">
                <FontAwesomeIcon icon={faUserFriends} />
                <div
                  className="add-people"
                  onClick={() => {
                    this.addPeople(+1);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div
                  className="remove-people"
                  onClick={() => {
                    this.addPeople(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </div>
                <div readOnly className="select-people select" type="text">
                  <div className="amount-people">
                    {/* {this.state.amountCabins > 1
                      ? this.state.amountCabins +
                      " " +
                      translate.houses[settings.lang]
                      : this.state.amountCabins +
                      " " +
                      translate.house[settings.lang]}
                    ,{" "} */}
                    {this.state.guests === 1
                      ? this.state.guests + " " + translate.guest[settings.lang]
                      : this.state.guests +
                      " " +
                      translate.guests[settings.lang]}
                  </div>
                </div>
              </div>
              <div ref={this.state.fixedBookedBtn} className="th">
                <div
                  className={
                    this.state.bookBtn && this.state.checkAvailability
                      ? "book-btn"
                      : ""
                  }
                >
                  {this.state.checkAvailability ? (
                    <div className="btn-shadow"></div>
                  ) : (
                    ""
                  )}
                  {this.state.readyForBooking ? (
                    <Link
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                      to={"cabinOffers"}
                    >
                      <div className="read-book-btn all-btn">
                        <div>{translate.book[settings.lang]}</div>
                      </div>
                    </Link>
                  ) : this.state.missingCabin ? (
                    <div className={"all-btn disable-btn"}>
                      <div>{translate.book[settings.lang]}</div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.checkAvailability();
                      }}
                      className={"all-btn check-avail-btn"}
                    >
                      <div>{translate.checkAvail[settings.lang]}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="loading">
          {this.state.loading ? <FontAwesomeIcon spin="true" icon={faSpinner}></FontAwesomeIcon> : ''}
        </div>

        {this.state.checkAvailability ? (


          <div className="cabin-list">
            <div className="wrap-cabin-list">{cabins}</div>
          </div>

        )
          : (
            ""
          )
        }
      </content>
    );
  }
}

export default withRouter(Availability);
