import React, { Component } from "react";
import settings from "../../../settings.json";
import ImageUploader from "react-images-upload";
import "./cabins.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faTrashAlt,
  faSpinner,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import defaultImage from "../../../assets/default-placeholder.png";
import { v4 as uuidv4 } from "uuid";

class Cabins extends Component {
  cabinOffers = [];
  cabinSettings = [];
  defaultImage = defaultImage;
  validEmailCleaning = true;
  validEmailUser = true;
  validEmailOffers = true;
  maxOffers = true;
  cabins = [];
  uuidv4 = uuidv4;
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? settings.domain
          : settings.domain,
      protocol: settings.protocol + "//",
    };

    this.axios = require("axios");
    this.getCabinOffers();
    this.state = {
      submenu: sessionStorage.getItem("submenu")
        ? sessionStorage.getItem("submenu")
        : "House",
      loading: false,
      cabins: [],
      mainImage: new Date().getTime(),
      activeList: -1,
      theInputKey: "",
      imageLoadGallery: false,
      imageLoadMain: false,
      cabinImages: [{ src: "", index: -1, imageCount: -1, id: -1 }],
    };

    this.uploadMainImage = this.uploadMainImage.bind(this);
  }
  uploadMainImage(picture, id) {
    this.setState({ imageLoadMain: true, loading: true });
    var uuid4 = this.uuidv4();
    var cabins = this.state.cabins.map((value) => {
      if (value.id === id) {
        value.mainImage = uuid4 + "_" + id + "_mainImage.png";
      }
      return value;
    });
    this.upload(
      picture[picture.length - 1],
      uuid4 + "_" + id + "_mainImage.png"
    ).then((res) => {
      this.cabins = cabins;
      this.setState({
        cabins,
        mainImage: new Date().getTime(),
        loading: false,
        theInputKey: new Date().valueOf(),
      });
    });
  }

  uploadGallery(picture, index, id) {
    this.upload(picture, "gallery", id, index);
  }

  upload(picture, define, id, index) {
    this.setState({ loading: true });
    var formData = new FormData();
    var cabins = this.state.cabins;
    var addImages = [];
    var cabinImages = this.state.cabinImages;
    if (define === "gallery") {
      picture.forEach((element, i) => {
        var imageName = this.uuidv4() + "_" + define + "_" + id + ".png";
        addImages.push(imageName);
        formData.append("fileToUpload[]", element, imageName);
      });

      cabins = this.state.cabins.map((value, s) => {
        if (value.id === id) {
          var gallery = JSON.parse(value.gallery);
          addImages.forEach((image) => {
            cabinImages.push({ src: image, id });
            gallery.push(image);
          });
          value.gallery = JSON.stringify(gallery);
        }
        return value;
      });
    } else {
      formData.append("fileToUpload", picture, define);
    }

    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/uploadImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.cabins = cabins;
        this.setState({
          imageLoadGallery: false,
          imageLoadMain: false,
          fileKey: new Date().getTime(),
          theInputKey: new Date().valueOf(),
          loading: false,
          cabins,
          cabinImages,
        });

        this.setState({});
        if (res.data === 1) {
          return res;
        } else {
          alert(res.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  getCabins() {
    let beds24;
    if (settings.beds24) {
      beds24 = {
        endpoint: "getProperties",
      }

    }
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php",
        { beds24 }
      )
      .then((response) => {

        response.data = response.data.map((cabin, i) => {
          console.log(cabin);
          if (settings.beds24) {
            const room = cabin.rooms.find(x => x.roomId === this.state.roomId)
            cabin.textShort = room?.name
            cabin.textShortFo = room?.name
            cabin.textLong = room?.roomDescription
            cabin.textLongFo = room?.roomDescription
            cabin.mainImage = defaultImage;
            if (cabin.gallery && cabin.gallery.length > 0) {
              cabin.mainImage = cabin.gallery[0]?.url;
            }
            cabin.maxGuests = "0"
            cabin.minDates = "0"
            cabin.roomTypes.forEach((elme) => {
              if (elme.maxPeople > cabin.maxGuests) {
                cabin.maxGuests = elme.maxPeople;
              }
              if (elme.minStay >= cabin.minDates) {
                cabin.minDates = elme.minStay === "0" ? 1 : elme.minStay;
              }

            })
          }
          return cabin;
        })
        var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
        var data = [];
        if (userLogin.some((x) => x.role === "user")) {
          data = response.data.filter((a) =>
            userLogin.some((c) => c.cabinId === a.id)
          );
        } else {
          data = response.data;
        }
        var newGallery = [];
        data.forEach((data, index) => {

          var gallery = data.gallery;
          if (typeof gallery === 'string') {
            gallery = JSON.parse(gallery)
          }
          if (gallery === null) {
            return;
          }
      
          if (settings.beds24) {
            gallery.forEach((image) => {
              newGallery.push({ src: image.url, index, id: data.id });
            });
          } else {
            gallery.forEach((image) => {
              newGallery.push({ src: image, index, id: data.id });
            });
          }
        });
        this.getCabinSettings();
        this.setState({
          cabins: data,
          cabinImages: newGallery,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCabinOffers() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabinOffers.php"
      )
      .then((response) => {
        if (response.data !== "") {
          this.cabinOffers = response.data;
        } else {
          this.cabinOffers = [];
        }
        this.getCabins();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCabinSettings() {
    this.cabinSettings = [];
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabinSettings.php"
      )
      .then((response) => {
        if (response.data !== "") {
          this.cabinSettings = response.data;
          this.setState({});
        } else {
          this.cabinSettings = [];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  numberOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  validateEmail(emailField, define) {
    var reg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    if (define === "user") {
      this.validEmailUser = reg.test(emailField);
      if (emailField === "") {
        this.validEmailUser = true;
      }
    } else if (define === "offer") {
      this.validEmailOffers = reg.test(emailField);
      if (emailField === "") {
        this.validEmailOffers = true;
      }
    } else {
      this.validEmailCleaning = reg.test(emailField);
      if (emailField === "") {
        this.validEmailCleaning = true;
      }
    }
    this.setState({});
  }

  save() {

    var cabins = this.state.cabins;
    if (
      !this.validEmailUser ||
      !this.validEmailCleaning ||
      !this.validEmailOffers
    ) {
      alert("Email not valid");
      return;
    }
    if (this.state.submenu === "House") {
      cabins = this.cabins.map((element) => {
        element.textLong = element.textLong.replace("'", "’");
        element.textShort = element.textShort.replace("'", "’");
        element.textLongFo = element.textLongFo.replace("'", "’");
        element.textShortFo = element.textShortFo.replace("'", "’");
        element.cabinName = element.cabinName.replace("'", "’");
        return element;
      });
      console.log(cabins)
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateCabins.php",
          cabins
        )
        .then((response) => {
          // this.state.cabins.map((value) => {
          //   return (value.new = false);
          // });
          this.cabins.map(x => x.new = false)
          this.setState({ cabins: this.state.cabins });
          if (response.data) {
            alert("Data saved successfully!");
            this.getCabins();
          } else {
            alert("Saved failed");
          }
        });
    }
    if (this.state.submenu === "Offers") {
      var cabinOffers = this.cabinOffers;
      cabinOffers = cabinOffers.map((element) => {
        element.description = element.description.replace("'", "’");
        element.name = element.name.replace("'", "’");
        return element;
      });

      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateCabinOffers.php",
          cabinOffers
        )
        .then((response) => {
  
          if (response.data) {
            this.cabinOffers.map((x) => (x.new = false));
            alert("Data saved successfully!");
          } else {
            alert("Saved failed");
          }
        });
    }
    if (this.state.submenu === "Settings") {
      var cabinSettings = this.cabinSettings;
      cabinSettings = cabinSettings.map((element) => {
        element.pin = element.pin.replace("'", "’");
        return element;
      });
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateCabinSettings.php",
          cabinSettings
        )
        .then((response) => {

          if (response.data) {
            alert("Data saved successfully!");
            this.cabinSettings.map((x) => (x.new = false));
            this.getCabinOffers();
          } else {
            alert("Saved failed");
          }
        }).catch((error) => {
          console.log(error)
        });
    }
  }
  openList(index) {
    this.state.activeList !== index
      ? this.setState({ activeList: index })
      : this.setState({ activeList: -1 });
  }
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  inputChange(event, define, index) {
    var cabins = "";
    this.cabins = [];
    if (define === "airbnbLink") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.airbnbLink = event.target.value;
        }
        return value;
      });
    }
    if (define === "airbnbSync") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.airbnbSync = event.target.checked ? "1" : "0";
        }
        return value;
      });
    }
    if (define === "beds24PropKey") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          if (value.beds24PropKey === undefined) {
            value.new = true;
          }
          value.beds24PropKey = event.target.value;
        }
        return value;
      });
    }
    if (define === "onRequest") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.onRequest = event.target.checked;
        }
        return value;
      });
    }
    if (define === "checkInTime") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.checkInTime = event.target.value;
        }
        return value;
      });
    }
    if (define === "checkOutTime") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.checkOutTime = event.target.value;
        }
        return value;
      });
    }
    if (define === "cabinName") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.cabinName = event.target.value;
        }
        return value;
      });
    }
    if (define === "price") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.price = event.target.value;
        }
        return value;
      });
    }
    if (define === "cleaning") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.cleaning = event.target.value;
        }
        return value;
      });
    }
    if (define === "maxGuests") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.maxGuests = event.target.value;
        }
        return value;
      });
    }
    if (define === "minDates") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.minDates = event.target.value;
        }
        return value;
      });
    }
    if (define === "blockDays") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          value.blockDays = event.target.value;
        }
        return value;
      });
    }
    if (define === "textShort") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.textShort = event;
          } else {
            value.textShortFo = event;
          }
        }
        return value;
      });
    }
    if (define === "specialComments") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.specialComments = event;
          } else {
            value.specialCommentsFo = event;
          }
        }
        return value;
      });
    }
    if (define === "textLong") {
      cabins = this.state.cabins.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.textLong = event;
          } else {
            value.textLongFo = event;
          }
        }
        return value;
      });
    }
    this.cabins = cabins;
  }
  // onBlur() {

  //   if(this.cabins.length > 0){
  //     setTimeout(()=>{
  //       this.setState({ cabins: this.cabins });
  //     }, 100)

  //   }
  // }
  removeCabinImage(src, index) {
    var cabins = this.state.cabins.map((value, s) => {
      if (index === s) {
        var gallery = JSON.parse(value.gallery);
        gallery.splice(gallery.indexOf(src), 1);
        value.gallery = JSON.stringify(gallery);
      }
      return value;
    });

    var cabinImages = this.state.cabinImages.filter((value) => {
      return value.src !== src;
    });
    this.setState({ cabins, cabinImages });
  }

  addCabin() {
    var cabins = this.state.cabins;
    var newCabin = {
      cabinName: "New Cabin",
      price: "",
      maxGuests: "",
      cleaning: "",
      minDates: "",
      textShort: "",
      textLong: "",
      textShortFo: "",
      textLongFo: "",
      mainImage: "",
      gallery: "[]",
      new: true,
      id: this.uuidv4(),
    };
    cabins.push(newCabin);
    this.setState({ cabins });
  }

  deleteCabin(id) {
    var r = window.confirm("Sure you wan't to delete this house ");
    if (r === true) {
      const index = this.state.cabins.findIndex((x) => {
        return x.id === id;
      });
      this.state.cabins.splice(index, 1);
      var cabinImages = this.state.cabinImages.filter((x) => x.index !== index);
      this.setState({ cabins: this.state.cabins, activeList: -1, cabinImages });
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteCabin.php",
          { id }
        )
        .then((response) => {
          if (response.data) {
            alert("Cabin deleted successfully!");
          } else {
            alert("Deleted failed");
          }
        });
    }
  }
  submenu(value) {
    if (value === "House") {
      this.setState({ submenu: value });
    } else {
      this.setState({ submenu: value });
    }
    sessionStorage.setItem("submenu", value);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(img, ev) {
    ev.dataTransfer.setData("img", img);
  }
  swapArrayElements = function (arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };
  drop(i, ev, id) {
    ev.preventDefault();
    var img = ev.dataTransfer.getData("img");
    this.state.cabins = this.state.cabins.map((element) => {
      if (element.id === id) {
        element.gallery = JSON.parse(element.gallery);
        let swap = this.swapArrayElements(element.gallery, element.gallery.findIndex(x => x === i), element.gallery.findIndex(x => x === img));
        element.gallery = JSON.stringify(swap);
        this.cabins.push(element)
      }
      return element
    })
    var newGallery = [];
    this.state.cabins.forEach((data, index) => {
      var gallery = JSON.parse(data.gallery);
      gallery.forEach((image) => {
        newGallery.push({ src: image, index, id: data.id });
      });
    });
    this.getCabinSettings();
    this.setState({
      cabins: this.state.cabins,
      cabinImages: newGallery,
    });
  }
  addOffer(id) {
    this.cabinOffers.push({
      new: true,
      unit: 0,
      maxOffers: 0,
      cabinId: id,
      images: "",
      name: "",
      description: "",
      price: "",
    });
    this.setState({});
  }
  deleteOffer(index) {
    var r = window.confirm("Sure you wan't to delete this offer ");
    if (r === true) {
      var id = this.cabinOffers[index].id;

      this.cabinOffers.splice(index, 1);
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteOffer.php",
          { id }
        )
        .then((response) => {
          if (response.data) {
            this.setState({});
            alert("Offer deleted successfully!");
          } else {
            alert("Deleted failed");
          }
        });
    }
  }
  updateOffers(event, define, index) {
    switch (define) {
      case "name":
        if (settings.lang === "en") {
          this.cabinOffers[index].name = event.target.value;
        } else {
          this.cabinOffers[index].nameFo = event.target.value;
        }
        break;
      case "exclude":
        this.cabinOffers[index].exclude = event.target.checked;
        break;
      case "required":
        this.cabinOffers[index].required = event.target.checked;
        break;
      case "description":
        if (settings.lang === "en") {
          this.cabinOffers[index].description = event.target.value;
        } else {
          this.cabinOffers[index].descriptionFo = event.target.value;
        }
        // code block
        break;
      case "email":
        this.validateEmail(event.target.value, "offer");
        if (this.validEmailOffers) {
          this.cabinOffers[index].email = event.target.value;
        }
        // code block
        break;
      case "price":
        this.cabinOffers[index].price = event.target.value;
        // code block
        break;
      case "unit":
        this.cabinOffers[index].unit = event.target.value;
        if (event.target.value === "1") {
          this.maxOffers = true;
        }
        this.setState({});
        // code block
        break;
      case "maxOffers":
        this.cabinOffers[index].maxOffers = event.target.value;
        // code block
        break;
      default:
      // code block
    }
  }
  updateSettings(event, define, id, cabinId) {
    var cabinIndex = this.cabinSettings.findIndex((x) => x.cabinId === cabinId);

    if (cabinIndex === -1) {
      this.cabinSettings.push({
        id,
        pin: "",
        pinFo: "",
        emailCleaning: "",
        emailUser: "",
        psw: "",
        cabinId,
        new: true,
      });
    }

    var index = this.cabinSettings.findIndex((x) => x.id === id);
    this.cabinSettings[index].cabinId = cabinId;
    switch (define) {
      case "pin":
        if (settings.lang === "en") {

          this.cabinSettings[index].pin = event;
        } else {

          this.cabinSettings[index].pinFo = event;
        }
        break;
      case "emailCleaning":
        this.cabinSettings[index].emailCleaning = event.target.value;
        // code block
        break;
      case "emailUser":
        this.cabinSettings[index].emailUser = event.target.value;
        // code block
        break;
      case "psw":
        this.cabinSettings[index].psw = event.target.value;
        // code block
        break;
      default:
      // code block
    }
  }
  uploadOfferImage(picture, index, id) {
    this.setState({ loading: true });
    var imageName = this.uuidv4() + "_offerImage_" + id + ".png";
    this.cabinOffers.map((value) => {
      if (value.id === id) {
        value.images = imageName;
      }
      return value;
    });
    this.upload(picture[picture.length - 1], imageName).then((res) => {
      this.setState({
        loading: false,
        theInputKey: new Date().valueOf(),
      });
    });
  }
  render() {
    var cabins = this.state.cabins.map((cabin, index) => {
      var list = null;
      var cabinSettings = [];
      if (this.state.submenu === "Settings") {
        cabinSettings = this.cabinSettings.filter(
          (x) => x.cabinId === cabin.id
        );

        list = (
          <div
            className={
              this.state.activeList === index
                ? "active-list list-content settings"
                : "list-content settings"
            }
          >
            {cabinSettings.length > 0 ? (
              cabinSettings.map((value, index) => {
                return (
                  <div key={index}>
                    <div>
                      <label>Email cleaning</label>
                      <input
                        className={!this.validEmailCleaning ? "not-valid" : ""}
                        defaultValue={value.emailCleaning}
                        onBlur={(event) => { this.validateEmail(event.target.value, "cleaning") }}
                        onChange={(event) => {

                          this.updateSettings(
                            event,
                            "emailCleaning",
                            value.id,
                            cabin.id
                          );
                        }}
                      />
                    </div>
                    <div>
                      <label>Email owner</label>
                      <input
                        className={!this.validEmailUser ? "not-valid" : ""}
                        defaultValue={value.emailUser}
                        onBlur={(event) => { this.validateEmail(event.target.value, "user") }}
                        onChange={(event) => {

                          this.updateSettings(
                            event,
                            "emailUser",
                            value.id,
                            cabin.id
                          );
                        }}
                      />
                    </div>
                    <div>
                      <label>Password for owner login</label>
                      <input
                        onChange={(event) => {
                          this.updateSettings(event, "psw", value.id, cabin.id);
                        }}
                      />
                    </div>
                    <div className="full-width">
                      <div>
                        <label>Extra info on PDF <i>{settings.lang}</i></label>
                        <ReactQuill
                          defaultValue={settings.lang === 'en' ? value.pin : value.pinFo}
                          onChange={(event) => {
                            this.updateSettings(
                              event,
                              "pin",
                              value.id,
                              cabin.id
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div key={index}>
                <div>
                  <label>Extra info on PDF </label>
                  <ReactQuill
                    onChange={(event) => {
                      this.updateSettings(event, "pin", -1, cabin.id);
                    }}
                  />
                </div>
                <div>
                  <label>Email cleaning</label>
                  <input
                    className={!this.validEmailCleaning ? "not-valid" : ""}
                    onChange={(event) => {
                      this.validateEmail(event.target.value, "cleaning");
                      this.updateSettings(event, "emailCleaning", -1, cabin.id);
                    }}
                  />
                </div>
                <div>
                  <label>Email owner</label>
                  <input
                    className={!this.validEmailUser ? "not-valid" : ""}
                    onChange={(event) => {
                      this.validateEmail(event.target.value, "user");
                      this.updateSettings(event, "emailUser", -1, cabin.id);
                    }}
                  />
                </div>
                <div>
                  <label>Password for owner login</label>
                  <input
                    onChange={(event) => {
                      this.updateSettings(event, "psw", -1, cabin.id);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      }
      if (this.state.submenu === "Offers") {
        list = (
          <div
            className={
              this.state.activeList === index
                ? "active-list list-content"
                : "list-content"
            }
          >
            <div
              onClick={() => {
                this.addOffer(cabin.id);
              }}
              className="add-offer"
            >
              <FontAwesomeIcon icon={faPlus} /> Add offer
            </div>
            <table>
              <tbody>
                <tr>
                  <th>Image</th>
                  <th>
                    Name <i>{settings.lang}</i>
                  </th>
                  <th>
                    Description <i>{settings.lang}</i>
                  </th>
                  <th>Revenue</th>
                  <th>Email</th>
                  <th>Price</th>
                  <th>Unit</th>
                  <th>Required</th>
                </tr>
                {this.cabinOffers.map((value, offersIndex) => {
                  if (cabin.id === value.cabinId) {
                    return (
                      <tr key={offersIndex}>
                        <td>
                          <ImageUploader
                            key={this.state.theInputKey || ""}
                            accept="PNG|JPEG|JPG"
                            singleImage={true}
                            withIcon={true}
                            buttonText="Choose images"
                            onChange={(e) => {
                              this.uploadOfferImage(e, offersIndex, value.id);
                            }}
                            imgExtension={[".jpg", ".jpeg", ".png"]}
                            maxFileSize={50000000000000}
                          />
                          <div className="offer-image">
                            <img
                              alt=""
                              src={
                                settings.beds24 ? value.images :
                                  value.images !== ""
                                    ? "" +
                                    this.settings.protocol +
                                    this.settings.domain +
                                    "/static/uploads/thumbnail_" +
                                    value.images +
                                    "?" +
                                    new Date().getTime()
                                    : this.defaultImage
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            defaultValue={
                              settings.lang === "en" ? value.name : value.nameFo
                            }
                            onChange={(event) => {
                              this.updateOffers(event, "name", offersIndex);
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            onChange={(event) => {
                              this.updateOffers(
                                event,
                                "description",
                                offersIndex
                              );
                            }}
                            defaultValue={
                              settings.lang === "en"
                                ? value.description
                                : value.descriptionFo
                            }
                          ></textarea>
                        </td>

                        <td>
                          <label>No</label>
                          <br></br>
                          <input
                            defaultChecked={value.exclude}
                            type="checkbox"
                            onChange={(event) => {
                              this.updateOffers(event, "exclude", offersIndex);
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            className={
                              !this.validEmailOffers ? "notValidEmail" : ""
                            }
                            onChange={(event) => {
                              this.updateOffers(event, "email", offersIndex);
                            }}
                            defaultValue={value.email}
                          />
                        </td>
                        <td>
                          <input
                            onKeyDown={(event) => {
                              this.numberOnly(event);
                            }}
                            onChange={(event) => {
                              this.updateOffers(event, "price", offersIndex);
                            }}
                            defaultValue={value.price}
                          />
                        </td>
                        <td>
                          <select
                            defaultValue={value.unit}
                            onChange={(event) => {
                              this.updateOffers(event, "unit", offersIndex);
                            }}
                          >
                            <option value="0">Per person</option>
                            <option value="1">PCS.</option>
                            <option value="4">Person per day</option>
                            <option value="2">Per person, per day</option>
                            <option value="3">Per day</option>
                          </select>
                          {value.unit === "1" || value.unit === "4" ? (
                            <div className="max-offers">
                              Max. pcs.
                              <input
                                onKeyDown={(event) => {
                                  this.numberOnly(event);
                                }}
                                onChange={(event) => {
                                  this.updateOffers(
                                    event,
                                    "maxOffers",
                                    offersIndex
                                  );
                                }}
                                defaultValue={value.maxOffers}
                              ></input>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <label>Yes</label>
                          <br></br>
                          <input
                            defaultChecked={value.required}
                            type="checkbox"
                            onChange={(event) => {
                              this.updateOffers(event, "required", offersIndex);
                            }}
                          ></input>
                        </td>
                        <td>
                          <div
                            className="delete-offers"
                            onClick={() => {
                              this.deleteOffer(offersIndex);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        );
      }
      if (this.state.submenu === "House") {
        list = (
          <div
            className={
              this.state.activeList === index
                ? "active-list list-content house"
                : "list-content house"
            }
          >
            <div className="cabin-name">
              <div>
                <p>House name</p>
                <input
                  defaultValue={cabin.cabinName}
                  onChange={(e) => {
                    this.inputChange(e, "cabinName", index);
                  }}
                />
              </div>
              <div>
                <p>Price (Pr. day)</p>
                <input
                  onKeyDown={(event) => {
                    this.numberOnly(event);
                  }}
                  defaultValue={cabin.price}
                  onChange={(e) => {
                    this.inputChange(e, "price", index);
                  }}
                />
              </div>
              <div>
                <p>Cleaning price (Pr. booking)</p>
                <input
                  onKeyDown={(event) => {
                    this.numberOnly(event);
                  }}
                  defaultValue={cabin.cleaning}
                  onChange={(e) => {
                    this.inputChange(e, "cleaning", index);
                  }}
                />
              </div>
              <div>
                <p>Max Guests</p>
                <input
                  onKeyDown={(event) => {
                    this.numberOnly(event);
                  }}
                  defaultValue={cabin.maxGuests}
                  onChange={(e) => {
                    this.inputChange(e, "maxGuests", index);
                  }}
                />
              </div>
              <div>
                <p>Check-in time </p>
                <input
                  defaultValue={cabin.checkInTime}
                  onChange={(e) => {
                    this.inputChange(e, "checkInTime", index);
                  }}
                />
              </div>
              <div>
                <p>Check-out time </p>
                <input
                  defaultValue={cabin.checkOutTime}
                  onChange={(e) => {
                    this.inputChange(e, "checkOutTime", index);
                  }}
                />
              </div>
              <div>
                <p>Block days before and after booking</p>
                <input
                  onKeyDown={(event) => {
                    this.numberOnly(event);
                  }}
                  defaultValue={cabin.blockDays}
                  onChange={(e) => {
                    this.inputChange(e, "blockDays", index);
                  }}
                />
              </div>
              <div>
                <p>Mininum booking dates </p>
                <input
                  onKeyDown={(event) => {
                    this.numberOnly(event);
                  }}
                  defaultValue={cabin.minDates}
                  onChange={(e) => {
                    this.inputChange(e, "minDates", index);
                  }}
                />

                <p className="sub-input">
                  <i>"Set to 0 to block booking"</i>
                </p>
              </div>
            </div>
            <div className="cabin-checkbox">
              <div>
                <p>Book on request </p>
                <input
                  type="checkbox"
                  defaultChecked={cabin.onRequest === "1" ? true : false}
                  onChange={(e) => {
                    this.inputChange(e, "onRequest", index);
                  }}
                />
              </div>
            </div>
            <div className="cabin-text">
              <div className="editor">
                <div className="cabin-special-comments">
                  <h3 className="heading">
                    Edit special comments <i>{settings.lang}</i>
                  </h3>
                  <ReactQuill
                    value={
                      settings.lang === "en"
                        ? cabin.specialComments
                        : cabin.specialCommentsFo
                    }
                    onChange={(event) => {
                      this.inputChange(event, "specialComments", index);
                    }}
                  />
                </div>
                <div className="text-short">
                  <h3 className="heading">
                    Edit short text <i>{settings.lang}</i>
                  </h3>
                  <ReactQuill
                    defaultValue={
                      settings.lang === "en"
                        ? cabin.textShort
                        : cabin.textShortFo
                    }
                    onChange={(event) => {
                      this.inputChange(event, "textShort", index);
                    }}
                  />
                </div>
                <div className="text-long">
                  <h3 className="heading">
                    Edit long text <i>{settings.lang}</i>
                  </h3>
                  <ReactQuill
                    defaultValue={
                      settings.lang === "en" ? cabin.textLong : cabin.textLongFo
                    }
                    onChange={(event) => {
                      this.inputChange(event, "textLong", index);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="full-width">
              <div className="airbnb-uri">
                <div>
                  <input
                    defaultChecked={cabin.airbnbSync === "1" ? true : false}
                    type="checkbox"
                    onChange={(event) => {
                      this.inputChange(event, "airbnbSync", index);
                    }}
                  ></input>
                  <label>Airbnb iCal</label>
                </div>

                <input
                  disabled={cabin.airbnbSync !== "1" ? true : false}
                  onChange={(event) => {
                    this.inputChange(event, "airbnbLink", index);
                  }}
                  defaultValue={cabin.airbnbLink}
                ></input>
              </div>
              <div className="beds24-propKey">
                <div>
                  <label>Beds24 propKey</label>
                </div>

                <input
                  onChange={(event) => {
                    this.inputChange(event, "beds24PropKey", index);
                  }}
                  defaultValue={cabin.beds24PropKey}
                ></input>
              </div>
              <div className="booklocal-uri">
                <label>Book Local iCal</label>
                <input
                  type="text"
                  disabled={true}
                  readOnly={true}
                  defaultValue={
                    "https://" +
                    this.settings.domain +
                    "/static/php/ical/cabin/" +
                    cabin.id
                  }
                ></input>
              </div>
            </div>
            <div className="images add-images">
              <p>
                Main image
                <span
                  className={
                    this.state.imageLoadMain ? "image-spinner" : "hide-spinner"
                  }
                >
                  <FontAwesomeIcon icon={faSpinner} spin />
                </span>
              </p>
              <div className="main-image">
                <img
                  alt=""
                  src={
                    settings.beds24 ? cabin.mainImage : cabin.mainImage.length > 0
                      ? "" +
                      this.settings.protocol +
                      this.settings.domain +
                      "/static/uploads/thumbnail_" +
                      cabin.mainImage +
                      "?" +
                      new Date().getTime() +
                      this.state.mainImage
                      : ""
                  }
                />
              </div>
              <ImageUploader
                key={this.state.theInputKey || ""}
                accept="PNG|JPEG|JPG"
                singleImage={true}
                withIcon={true}
                buttonText="Choose images"
                onChange={(e) => {
                  this.uploadMainImage(e, cabin.id);
                }}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={50000000000000}
              />
              <p>
                Gallery Images
                <span
                  className={
                    this.state.imageLoadGallery
                      ? "image-spinner"
                      : "hide-spinner"
                  }
                >
                  <FontAwesomeIcon icon={faSpinner} spin />
                </span>
              </p>
              <div className="gallery">
                {this.state.cabinImages.map((value, i) => {
                  if (value.id === cabin.id) {
                    return (
                      <div
                        onDrop={(event) => { this.drop(value.src, event, cabin.id) }}

                        draggable="true"
                        key={i} className="images">
                        <FontAwesomeIcon
                          onClick={() => {
                            this.removeCabinImage(value.src, index);
                          }}
                          icon={faTrashAlt}
                        />
                        <img
                          onDragOver={(event) => { this.allowDrop(event) }}
                          onDragStart={(event) => { this.drag(value.src, event) }}
                          alt=""
                          src={
                            settings.beds24 ? value.src : "" +
                              this.settings.protocol +
                              this.settings.domain +
                              "/static/uploads/thumbnail_" +
                              value.src +
                              "?" +
                              new Date().getTime()
                          }
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}

                <ImageUploader
                  accept="PNG|JPEG|JPG"
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={(file) => {
                    this.uploadGallery(file, index, cabin.id);
                  }}
                  key={this.state.theInputKey || ""}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={50000000000000}
                  ref={(iu) => (this.imageUploader = iu)}
                />
              </div>
              <div
                onClick={() => {
                  this.deleteCabin(cabin.id);
                }}
                className="delete-cabin"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          </div>
        );
      }
      return (
        <li key={this.uuidv4()}>
          <div
            className="list"
            onClick={() => {
              this.openList(index);
            }}
          >
            <span className="list-submenu">{this.state.submenu}: </span>

            {cabin.cabinName}
            <div className="list-icon">
              <FontAwesomeIcon
                icon={this.state.activeList === index ? faSortUp : faSortDown}
              />
            </div>
          </div>
          {list}
        </li>
      );
    });

    return (
      <content id="admin-cabins">
        {this.state.loading ? (
          <div className="loading">
            <div className="spinner">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="wrap-content">
          <div className="front-page-content">
            <div className="headline">
              <div className="wrap-head-lang">
                <h3>{this.state.submenu}</h3>
                <div>
                  <div>
                    <p
                      onClick={() => {
                        settings.lang = "fo";
                        this.setState({}, () => {
                          localStorage.setItem("lang", "fo");
                        });
                      }}
                      className={settings.lang === "fo" ? "active" : ""}
                    >
                      FO
                    </p>
                    <p
                      onClick={() => {
                        settings.lang = "en";
                        this.setState({}, () => {
                          localStorage.setItem("lang", "en");
                        });
                      }}
                      className={settings.lang === "en" ? "active" : ""}
                    >
                      EN
                    </p>
                  </div>
                  <div
                    className="add"
                    onClick={() => {
                      this.addCabin();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add house</span>
                  </div>
                  <div
                    onClick={() => {
                      this.save();
                    }}
                    className="save-btn"
                  >
                    <div>Save</div>
                  </div>
                </div>
              </div>
              <div className="sub-menu">
                <div
                  onClick={() => {
                    this.submenu("House");
                  }}
                  className={
                    this.state.submenu === "House"
                      ? "selected-sub-menu info"
                      : "info"
                  }
                >
                  House
                </div>
                <div
                  onClick={() => {
                    this.submenu("Offers");
                  }}
                  className={
                    this.state.submenu === "Offers"
                      ? "selected-sub-menu offers"
                      : "offers"
                  }
                >
                  Offers
                </div>
                <div
                  onClick={() => {
                    this.submenu("Settings");
                  }}
                  className={
                    this.state.submenu === "Settings"
                      ? "selected-sub-menu settings"
                      : "settings"
                  }
                >
                  Settings
                </div>
              </div>
            </div>
            <ul>{cabins}</ul>
          </div>
        </div>
      </content>
    );
  }
}

export default Cabins;
