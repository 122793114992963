import React, { Component } from "react";
import settings from "../../settings.json";
import { Link } from "react-router-dom";
import "./content.scss";
import { cabin, comment } from "../../assets/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Carousel from "react-images";
import ReactHtmlParser from "react-html-parser";
import translate from "../../translate.json";
import { getProperties } from "../../beds24/api";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import defaultImage from "../../assets/default-placeholder.png";

class Content extends Component {
  invalidStartDates = [];
  invalidEndDates = [];
  placeholderInvalidEndDates = [];
  placeholderInvalidStartDates = [];
  checkInCheckOutArr = [];
  startDate = new Date().setHours(0, 0, 0, 0);
  endDate = new Date();
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      name: settings.name,
    };
    this.bookedDates = [];
    this.clientInfo =
      sessionStorage.getItem("clientInfo") !== "undefined"
        ? JSON.parse(sessionStorage.getItem("clientInfo"))
        : false;
    if (this.clientInfo) {
      var startDate = new Date(this.clientInfo.startDate);

      startDate =
        startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear();
      if (this.clientInfo.endDate) {
        var endDate = new Date(this.clientInfo.endDate);

        endDate =
          endDate.getDate() +
          "." +
          (endDate.getMonth() + 1) +
          "." +
          endDate.getFullYear();
      } else {
        var endDate = "";
      }
    }

    this.state = {
      scrollToCalender: React.createRef(),
      startDate: this.clientInfo ? startDate : "",
      endDate: this.clientInfo ? endDate : "",
      mapedCabins: [],
      mapedPackages: [],
      mapedCamping: [],
      frontPageImages: [{ src: "" }],
      welcomeText: [],
      customersWord: [],
      wildLifeText: [],
      welcomeTextFo: [],
      customersWordFo: [],
      wildLifeTextFo: [],
      wildLifeImages: [{ src: "" }],
      contactInfo: [],
      social: [],
      firstName: { input: "", warning: "" },
      lastName: { input: "", warning: "" },
      email: { input: "", warning: "" },
      comment: { input: "", warning: "" },
      companyEmail: "",
      companyName: "",
      companyAddress: "",
      companyCity: "",
      companyVat: "",
      companyPhone: "",
      companyFacebook: "",
      companyInstagram: "",
      companyBooking: "",
      companyAirbnb: "",
      loading: true
    };
    this.invalidDate = [];
    this.axios = require("axios");
    this.getCabins();
    this.getExclusive();
    this.getCamping();
    this.getFrontPage().then((response) => { });
    this.getSettings();
  }


  getSettings() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            companyEmail: response.data[0].email,
            companyName: response.data[0].name,
            companyAddress: response.data[0].adress,
            companyCity: response.data[0].city,
            companyPhone: response.data[0].phone,
            companyVat: response.data[0].vatnr,
            companyBooking: response.data[0].booking_com,
            companyAirbnb: response.data[0].airbnb_com,
          });
        }
      });
  }
  getBooked() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBooking.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }
  getBookedPackages() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBookedPackages.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }

  sendContact() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/sendContactMessage.php",
        {
          firstName: this.state.firstName.input,
          lastName: this.state.lastName.input,
          email: this.state.email.input,
          comment: this.state.comment.input,
          companyEmail: this.state.companyEmail,
          companyName: this.state.companyName,
          companyAddress: this.state.companyAddress,
          companyCity: this.state.companyCity,
          companyPhone: this.state.companyPhone,
          companyVat: this.state.companyVat,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  getFrontPage() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getFrontPage.php"
      )
      .then((response) => {
        var frontPageImages = JSON.parse(response.data.frontPageImages).map(
          (value) => {
            const img =
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/uploads/full_" +
              value.src;
            value.src = img;
            return value;
          }
        );
        var wildLifeImages = JSON.parse(response.data.wildLifeImages).map(
          (value) => {
            var img = "";
            if (value.src !== "") {
              img =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/large_" +
                value.src;
            }
            value.src = img;
            return value;
          }
        );
        this.setState({
          frontPageImages,
          welcomeText: JSON.parse(response.data.welcomeText),
          customersWord: JSON.parse(response.data.customersWord),
          wildLifeText: JSON.parse(response.data.wildLifeText),
          welcomeTextFo: response.data.welcomeTextFo !== "" ? JSON.parse(response.data.welcomeTextFo) : '',
          customersWordFo: response.data.customersWordFo !== "" ? JSON.parse(response.data.customersWordFo) : '',
          wildLifeTextFo: response.data.wildLifeTextFo !== "" ? JSON.parse(response.data.wildLifeTextFo) : '',
          wildLifeImages,
          contactInfo: JSON.parse(response.data.contactInfo),
          social: JSON.parse(response.data.social),
        });
        return response.data;
      })
      .catch((error) => {
        // handle error
        console.log(error)
        return error;
      });
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  compareMinDate(a, b) {
    if (a.minDates > b.minDates) {
      return -1;
    }
    if (a.minDates < b.minDates) {
      return 1;
    }
    return 0;
  }
  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  getCabins() {
    this.invalidStartDates = [];
    this.invalidEndDates = [];
    var dateValueOf = new Date().valueOf();
    let beds24;
    if (settings.beds24) {
      beds24 = {
        endpoint: "getProperties"
      }

    }
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php", {
        beds24
      }
      )
      .then(async (resp) => {
        resp.data = resp.data.filter(x => x.rooms?.length > 0);

        if (resp.data.length > 0) {
          var response = resp.data;
          this.shuffleArray(response);
          if (settings.beds24) {
            const filterResponse = response;
            response = []

            filterResponse.forEach((elm) => {
              if (elm.gallery && elm.gallery.length > 0) {
                elm.mainImage = elm.gallery[0].url;
              }
              elm.roomTypes.forEach((room) => {
                const roomContent = elm.rooms.find(x => x.roomId === room.roomId);
                const description = roomContent?.roomDescription.length > 150 ? roomContent?.roomDescription.slice(0, 150).split(".")[0] + "..." : roomContent?.roomDescription;

                elm.maxGuests = room.maxPeople;
                elm.name = room.name;
                elm.roomId = room.roomId;
                elm.textShort = description;
                elm.textShortFo = description;
                elm.cabinName = roomContent.name !== "" ? roomContent.name : elm.cabinName;
                if (roomContent.images.length > 0) {
                  elm.mainImage1 = roomContent.images[0];
                }

                if (elm.mainImage1 && elm.mainImage.includes('/static/media/default-placeholder')) {
                  elm.mainImage = elm.mainImage1;
                }
                response.push(JSON.parse(JSON.stringify(elm)))
              })
            })
          }
          var sortData = response.sort(this.compareMinDate);

          
          const mapedCabins = sortData.map((cabin, i) => {

            let img =
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/uploads/medium_" +
              cabin.mainImage +
              "?" +
              dateValueOf;

            if (settings.beds24) {

              // cabin.textShort = cabin.name;
              // cabin.textShortFo = cabin.name;
              // cabin.id = cabin.propId;
              img = cabin?.mainImage;
            }
            this.invalidStartDates.push({
              cabin: cabin.cabinName,
              dates: [],
            });
            this.invalidEndDates.push({
              cabin: cabin.cabinName,
              dates: [],
            });

            this.checkInCheckOutArr.push({
              cabin: cabin.cabinName,
              checkIn: [],
              checkOut: [],
              both: [],
              onlyCheckIn: [],
              onlyCheckOut: [],
            });

            return (
              <div
                style={{
                  display: cabin.minDates === "0" ? "none" : "inline-block",
                }}
                key={i}
                className="cabin-boks"
              >
                {cabin.onRequest === "1" ? (
                  <div className="on-request">
                    <p>{translate.onrequest[settings.lang]}</p>
                  </div>
                ) : (
                  ""
                )}
                <img onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = cabin.mainImage1;
                }} alt="img" src={img} />
                <div className="cabin-headline">
                  {ReactHtmlParser(
                    cabin.cabinName
                  )}
                </div>
                <div className="cabin-text">
                  {ReactHtmlParser(
                    settings.lang === "en" ? cabin.textShort : cabin.textShortFo
                  )}
                </div>
                <div className="cabin-text">
                  <p>{ReactHtmlParser(
                    cabin.maxGuests
                  ) + " " + translate.guests[settings.lang]}
                  </p>
                </div>
                <Link key={i} to={"cabins?id=" + cabin.id + (cabin.roomId ? "&roomId=" + cabin.roomId : "")}>
                  {cabin.minDates !== "0" ? (
                    <div
                      onClick={() => this.handleCabinOnClick("cabins")}
                      className="cabin-button"
                    >
                      <div>{translate.viewDetails[settings.lang]}</div>
                    </div>
                  ) : (
                    <div
                      onClick={() => this.handleCabinOnClick("cabins")}
                      className="cabin-button cabin-button-sold-out"
                    >
                      <div>{translate.viewDetails[settings.lang]}</div>
                    </div>
                  )}
                </Link>
              </div>
            );
          });

          this.setState({ mapedCabins }, () => {
            this.getBooked().then((value) => {
              this.bookedDates = value === "" ? [""] : value;
              this.bookedDates.forEach((value, i) => {
                if (
                  value.paymentStatus === "Paid" ||
                  value.paymentStatus === "Faktura"
                ) {
                  var checkIn = new Date(value.checkIn.replace(",", ""));
                  checkIn.setDate(checkIn.getDate());
                  checkIn.setHours(0, 0, 0, 0);
                  var checkOut = new Date(value.checkOut.replace(",", ""));
                  checkOut.setDate(checkOut.getDate());
                  checkOut.setHours(0, 0, 0, 0);
                  var dateArray = this.getDates(checkIn, checkOut);
                  this.checkInCheckOutArr.map((info) => {
                    if (JSON.parse(value.cabins).includes(info.cabin)) {
                      if (!info.checkIn.includes(checkIn.valueOf())) {
                        info.checkIn.push(checkIn.valueOf());
                      }
                      if (!info.checkOut.includes(checkOut.valueOf())) {
                        info.checkOut.push(checkOut.valueOf());
                      }
                    }
                    return info;
                  });

                  dateArray.forEach((dateArr) => {
                    this.invalidStartDates.map((info) => {
                      if (JSON.parse(value.cabins).includes(info.cabin)) {
                        if (!info.dates.includes(dateArr)) {
                          if (dateArr !== checkIn.valueOf()) {
                            if (dateArr !== checkOut.valueOf()) {
                              return info.dates.push(dateArr);
                            }
                          }
                        }
                      }
                      return null;
                    });
                    this.invalidEndDates.map((info) => {
                      if (JSON.parse(value.cabins).includes(info.cabin)) {
                        if (!info.dates.includes(dateArr)) {
                          if (dateArr !== checkIn.valueOf()) {
                            if (dateArr !== checkOut.valueOf()) {
                              return info.dates.push(dateArr);
                            }
                          }
                        }
                      }
                      return null;
                    });
                  });
                }
              });
              if (this.placeholderInvalidStartDates.length === 0) {
                this.placeholderInvalidStartDates = this.invalidStartDates;
              }
              if (this.placeholderInvalidEndDates.length === 0) {
                this.placeholderInvalidEndDates = this.invalidEndDates;
              }
              this.loopeDate = new Date();
            });
          });
        }
        this.setState({ 'loading': false })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getExclusive() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getPackages.php"
      )
      .then((resp) => {
        var placeholderInvalidDates = [];
        if (resp.data.length > 0) {
          var response = resp.data;
          this.shuffleArray(response);
          response = response.filter((x) => x.hidden === "" && !JSON.parse(x.visibleLang).includes(settings.lang.toUpperCase()));
          const mapedPackages = response.map((packageInfo, i) => {
            placeholderInvalidDates.push({
              packageName: packageInfo.packageName,
              dates: [],
            });
            const img =
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/uploads/medium_" +
              packageInfo.mainImage;
            return (
              <div key={i} className="package-boks">
                <img alt="img" src={img} />
                <div className="border-wrap">
                  <div>
                    <div className="package-headline">
                      {ReactHtmlParser(
                        settings.lang === "en"
                          ? packageInfo.packageName
                          : packageInfo.packageNameFo
                      )}
                    </div>
                    <div className="package-text">
                      {ReactHtmlParser(
                        settings.lang === "en"
                          ? packageInfo.textShort
                          : packageInfo.textShortFo
                      )}
                    </div>
                  </div>
                  <Link key={i} to={"package?id=" + packageInfo.id}>
                    <div
                      onClick={() => this.handleCabinOnClick("package")}
                      className="package-button"
                    >
                      <div>View details</div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          });

          this.setState({ mapedPackages });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCamping() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCamping.php"
      )
      .then((resp) => {
        var placeholderInvalidDates = [];
        if (resp.data.length > 0) {
          var response = resp.data;
          this.shuffleArray(response);
          const mapedCamping = response.map((campingInfo, i) => {
            placeholderInvalidDates.push({
              campingName: campingInfo.campingName,
              dates: [],
            });
            const img =
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/uploads/medium_" +
              campingInfo.mainImage;
            return (
              <div key={i} className="cabin-boks">
                <img alt="img" src={img} />
                <div className="border-wrap">
                  <div className="cabin-headline">
                    {ReactHtmlParser(
                      settings.lang === "en"
                        ? campingInfo.campingName
                        : campingInfo.campingNameFo
                    )}
                  </div>
                  <div className="cabin-text">
                    {ReactHtmlParser(
                      settings.lang === "en"
                        ? campingInfo.textShort
                        : campingInfo.textShortFo
                    )}
                  </div>
                  <Link key={i} to={"camping?id=" + campingInfo.id}>
                    <div
                      onClick={() => this.handleCabinOnClick("camping")}
                      className="cabin-button camping-button"
                    >
                      <div>View details</div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          });

          this.setState({ mapedCamping });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleStartDate = (event, picker) => {
    this.startDate = new Date(picker.startDate._d);
    this.startDate.setHours(0, 0, 0, 0);

    this.setState({
      startDate:
        this.startDate.getDate() +
        "." +
        (this.startDate.getMonth() + 1) +
        "." +
        this.startDate.getFullYear(),
    });
  };
  handleEndDate = (event, picker) => {
    this.endDate = new Date(picker.endDate._d);
    this.endDate.setHours(0, 0, 0, 0);
    const oneDay = 24 * 60 * 60 * 1000;
    this.days = Math.round(
      Math.abs((new Date(this.startDate) - new Date(this.endDate)) / oneDay)
    );
    var error = false;
    this.loopDate = new Date(this.startDate);

    for (var i = 0; i <= this.days; i++) {
      if (this.loopDate <= this.endDate) {
        if (
          this.invalidDate.includes(
            this.loopDate.getFullYear() +
            "/" +
            (this.loopDate.getMonth() + 1) +
            "/" +
            this.loopDate.getDate()
          )
        ) {
          error = true;
        }
      }

      this.loopDate.setDate(this.loopDate.getDate() + 1);
    }
    if (error) {
      this.setState({
        occupied: translate.someOrAll[settings.lang],
        dateRange: "",
        startDate: "",
        endDate: "",
      });
    } else {
      this.setState({
        occupied: false,
      });
      this.setState({
        dateRange:
          '{"startDate":"' +
          this.startDate.getFullYear() +
          "/" +
          (this.startDate.getMonth() + 1) +
          "/" +
          this.startDate.getDate() +
          '", "endDate":"' +
          this.endDate.getFullYear() +
          "/" +
          (this.endDate.getMonth() + 1) +
          "/" +
          this.endDate.getDate() +
          '", "guests":"2"}',
      });
      this.setState({
        endDate:
          this.endDate.getDate() +
          "." +
          (this.endDate.getMonth() + 1) +
          "." +
          this.endDate.getFullYear(),
      });
    }
  };

  handleCabinOnClick = (event) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    this.props.parameterPass.page = event;
    sessionStorage.setItem("lastScrollPosition", window.scrollY);
  };

  scrollToFu = () => {
    var widthPerc = window.innerWidth;
    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };
  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  sendContactMessage() {
    var warning = false;
    if (this.state.firstName.input.length < 3) {
      warning = true;
      this.setState({
        firstName: {
          input: this.state.firstName.input,
          warning: translate.minCharName[settings.lang],
        },
      });
    }
    if (this.state.lastName.input.length < 3) {
      warning = true;
      this.setState({
        lastName: {
          input: this.state.lastName.input,
          warning: translate.minCharLast[settings.lang],
        },
      });
    }
    if (!this.validateEmail(this.state.email.input)) {
      warning = true;
      this.setState({
        email: {
          input: this.state.email.input,
          warning: translate.minEmail[settings.lang],
        },
      });
    }

    if (this.state.comment.input.length < 20) {
      warning = true;
      this.setState({
        comment: {
          input: this.state.comment.input,
          warning: translate.minComment[settings.lang],
        },
      });
    }

    if (!warning) {
      this.sendContact()
        .then((value) => {
          if (value) {
            this.setState({ messageSent: true });
          } else {
            this.setState({ messageSent: false });
          }
          setTimeout(() => {
            this.setState({
              messageSent: false,
              firstName: { input: "", warning: "" },
              lastName: { input: "", warning: "" },
              email: { input: "", warning: "" },
              comment: { input: "", warning: "" },
            });
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  go(define) {
    setTimeout(() => {
      sessionStorage.setItem("lastScrollPosition", window.scrollY);
    }, 100);
    if (this.settings.name.includes("booklocal.fo")) {
      if (settings.houses) {
        if (define === "houses") {
          window.location.hash = "availability";
        } else {
          window.location.hash = "campingAvailability";
        }
      } else {
        window.location.hash = "packageAvailability";
      }
    }
    if (this.state.dateRange || this.clientInfo) {
      sessionStorage.setItem(
        "clientInfo",
        this.state.dateRange
          ? this.state.dateRange
          : JSON.stringify(this.clientInfo)
      );
      if (settings.houses) {
        window.location.hash = "availability";
      } else {
        window.location.hash = "packageAvailability";
      }
    } else {
      this.setState({ occupied: translate.pickDate[settings.lang] });
    }
  }
  checkAvailabiltyStartDate(d, customDate) {
    var invalidStartDates = [];
    invalidStartDates = this.placeholderInvalidStartDates;
    if (customDate) {
      for (let [index, element] of this.checkInCheckOutArr.entries()) {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidStartDates[index].dates.includes(d.valueOf())) {
              invalidStartDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          return "checkIn";
        }
        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          return "checkOut";
        }
        return false;
      }
    } else {
      this.checkInCheckOutArr.map((element, index) => {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidStartDates[index].dates.includes(d.valueOf())) {
              invalidStartDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          invalidStartDates[index].dates.push(d.valueOf());
        }
        return element;
      });

      return invalidStartDates.every((x) => {
        return x.dates.includes(d.valueOf());
      });
    }
  }
  checkAvailabiltyEndDate(d, customDate) {
    var invalidEndDates = [];
    invalidEndDates = this.placeholderInvalidEndDates;
    if (customDate) {
      for (let [index, element] of this.checkInCheckOutArr.entries()) {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidEndDates[index].dates.includes(d.valueOf())) {
              invalidEndDates[index].dates.push(d.valueOf());
            }
          }
        }
        if (
          element.checkIn.includes(d.valueOf()) &&
          !element.checkOut.includes(d.valueOf())
        ) {
          element.onlyCheckIn.push(d.valueOf());
          return "checkIn";
        }
        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          return "checkOut";
        }
        return false;
      }
    } else {
      this.checkInCheckOutArr.map((element, index) => {
        if (
          element.checkIn.includes(d.valueOf()) &&
          element.checkOut.includes(d.valueOf())
        ) {
          if (!element.both.includes(d.valueOf())) {
            element.both.push(d.valueOf());
            if (!invalidEndDates[index].dates.includes(d.valueOf())) {
              invalidEndDates[index].dates.push(d.valueOf());
            }
          }
        }

        if (
          element.checkOut.includes(d.valueOf()) &&
          !element.checkIn.includes(d.valueOf())
        ) {
          element.onlyCheckOut.push(d.valueOf());
          invalidEndDates[index].dates.push(d.valueOf());
        }
        return element;
      });

      return invalidEndDates.every((x) => {
        return x.dates.includes(d.valueOf());
      });
    }
  }

  render() {
    return (

      <content>
        <div className="carousel" ref={this.props.parameterPass.home}>
          <Carousel
            footerCount={(data) => { }}
            frameProps={{ autoSize: "height" }}
            views={this.state.frontPageImages}
          />
        </div>
        <div className="about" ref={this.props.parameterPass.booking}>
          <div className="about-content1">

            {ReactHtmlParser(
              settings.lang === "en"
                ? this.state.welcomeText.headline
                : this.state.welcomeTextFo.headline
            )}
            {ReactHtmlParser(
              settings.lang === "en"
                ? this.state.welcomeText.text
                : this.state.welcomeTextFo.text
            )}
            {this.settings.name.includes("booklocal.fo") ? (
              <div>
                <span
                  onClick={() => {
                    this.go("houses");
                  }}
                >
                  <input
                    className="go-button"
                    type="button"
                    value={translate.checkAvailHouse[settings.lang]}
                  />
                </span>
                {this.state.mapedCamping.length > 0 ? (
                  <span
                    onClick={() => {
                      this.go("camping");
                    }}
                  >
                    <input
                      className="go-button camping"
                      type="button"
                      value={translate.checkAvailCamping[settings.lang]}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <span></span>
            )}
          </div>
          {this.settings.name.includes("booklocal.fo") ? (
            <div className="about-content2">
              <div>
                <div className="comment">
                  <img alt="img" src={comment} />
                  <p>{translate.fewWord[settings.lang]}:</p>
                  {this.state.customersWordFo !== null ? ReactHtmlParser(this.state.customersWordFo.comment) : ''}
                </div>
              </div>
            </div>
          ) : (
            <div className="about-content2">
              <p>{translate.checkAvail[settings.lang]}</p>
              <div className="available-headline">
                {translate.checkIn[settings.lang]}
              </div>

              <div className="check-availabilty">
                <FontAwesomeIcon icon={faCalendarAlt} />

                <DateRangePicker
                  className="startDate"
                  singleDatePicker={true}
                  autoApply={true}
                  locale={{ format: "D.M.YYYY" }}
                  disabledDates={["02/25/2015"]}
                  isInvalidDate={(date) => {
                    const d = new Date(date._d);
                    if (settings.houses) {
                      if (this.checkAvailabiltyStartDate(d, false)) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      if (
                        d.valueOf() >= new Date().setHours(0, 0, 0, 0).valueOf()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }}
                  onApply={this.handleStartDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.startDate.length > 0
                        ? this.state.startDate
                        : ""
                    }
                  />
                </DateRangePicker>
                <span>
                  <input
                    className="go-button hidden-button"
                    type="button"
                    value={translate.go[settings.lang]}
                  />
                </span>
              </div>

              <div className="available-headline">
                {translate.checkOut[settings.lang]}
              </div>
              <div className="check-availabilty">
                <FontAwesomeIcon icon={faCalendarAlt} />
                <DateRangePicker
                  autoApply={true}
                  singleDatePicker={true}
                  locale={{ format: "D.M.YYYY" }}
                  startDate={this.startDate}
                  isInvalidDate={(date) => {
                    const d = new Date(date._d);
                    if (settings.houses) {
                      if (
                        this.checkAvailabiltyEndDate(d) ||
                        d.valueOf() < this.startDate.valueOf()
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      if (d.valueOf() >= this.startDate.valueOf()) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }}
                  onApply={this.handleEndDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.endDate.length > 0 ? this.state.endDate : ""
                    }
                  />
                </DateRangePicker>
                <span
                  onClick={() => {
                    this.go();
                  }}
                >
                  <input
                    className="go-button"
                    type="button"
                    value={translate.go[settings.lang]}
                  />
                </span>
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <div className="comment">
                  <img alt="img" src={comment} />
                  <p>{translate.fewWord[settings.lang]}:</p>
                  {ReactHtmlParser(this.state.customersWordFo.comment)}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.loading ?? <div>Loading</div>}
        {!this.state.loading ?? this.state.mapedCabins.length > 0 ? (
          <div className="cabins" ref={this.props.parameterPass.about}>
            <div className="wrap-text-cabins">
              <div className="cabins-text">
                <h1>{translate.ourHouses[settings.lang]}</h1>
                <p>{translate.ACCOMMODATIONS[settings.lang]}</p>
              </div>

              <div className="wrap-cabins">{this.state.mapedCabins}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.mapedCamping.length > 0 ? (
          <div
            className="cabins camping"
            ref={this.props.parameterPass.camping}
          >
            <div className="wrap-text-cabins">
              <div className="cabins-text">
                <h1>{translate.camping[settings.lang]}</h1>
                <p>{translate.campingTent[settings.lang]}</p>
              </div>
              <div className="wrap-cabins">{this.state.mapedCamping}</div>
            </div>
          </div>
        ) : (
          ""
        )}{" "}
        {this.state.mapedPackages.length > 0 &&
          <div
            className="cabins exclusive"
            ref={this.props.parameterPass.exclusive}
          >
            <div className="wrap-text-cabins">
              <div className="cabins-text">
                <h1>{translate.seedo[settings.lang]}</h1>
                <p>{translate.offPack[settings.lang]}</p>
              </div>
              <div className="wrap-packages">{this.state.mapedPackages}</div>
            </div>
          </div>
        }

        {this.state.wildLifeImages[0] &&
          <div className="wild-life">
            <div style={{ display: this.state.wildLifeImages[0].src !== "" ? 'block' : 'none' }} className="wrap-wild-life">
              <div className="wild-life-text">
                {ReactHtmlParser(
                  settings.lang === "en"
                    ? this.state.wildLifeText.headline
                    : this.state.wildLifeTextFo.headline
                )}
                {ReactHtmlParser(
                  settings.lang === "en"
                    ? this.state.wildLifeText.text
                    : this.state.wildLifeTextFo.text
                )}
              </div>
              <div className="wild-life-image">
                <Carousel
                  footerCount={(data) => { }}
                  frameProps={{ autoSize: "height" }}
                  views={this.state.wildLifeImages}
                />
              </div>
            </div>
          </div>
        }
        <div className="contact" ref={this.props.parameterPass.contact}>
          <div className="contact-form">
            <h1>{translate.getTouch[settings.lang]}</h1>
            <div className="contact-name">
              <div>
                <p>{translate.name[settings.lang]}</p>
                <input
                  value={this.state.firstName.input}
                  onChange={(event) => {
                    this.setState({
                      firstName: {
                        input: event.target.value.replace(/['"]+/g, "’"),
                        warning: false,
                      },
                    });
                  }}
                  type="text"
                  className="first-name"
                  name="firstName"
                  placeholder={translate.name[settings.lang]}
                />
                <span className="warning-message">
                  {this.state.firstName.warning}
                </span>
              </div>
              <div>
                <p>{translate.lastName[settings.lang]}</p>
                <input
                  value={this.state.lastName.input}
                  onChange={(event) => {
                    this.setState({
                      lastName: {
                        input: event.target.value.replace(/['"]+/g, "’"),
                        warning: false,
                      },
                    });
                  }}
                  type="text"
                  className="last-name"
                  name="lastName"
                  placeholder={translate.lastName[settings.lang]}
                />
                <span className="warning-message">
                  {this.state.lastName.warning}
                </span>
              </div>
            </div>
            <div className="email">
              <p>{translate.email[settings.lang]}</p>
              <input
                value={this.state.email.input}
                onChange={(event) => {
                  this.setState({
                    email: {
                      input: event.target.value.replace(/['"]+/g, "’"),
                      warning: false,
                    },
                  });
                }}
                type="email"
                name="email"
                placeholder={translate.email[settings.lang]}
              />
              <span className="warning-message">
                {this.state.email.warning}
              </span>
            </div>
            <div className="comment">
              <p>{translate.message[settings.lang]}</p>
              <div>
                <textarea
                  value={this.state.comment.input}
                  onChange={(event) => {
                    this.setState({
                      comment: {
                        input: event.target.value.replace(/['"]+/g, "’"),
                        warning: false,
                      },
                    });
                  }}
                  type="text"
                  name="comment"
                  placeholder={translate.message[settings.lang]}
                />
                <span className="warning-message">
                  {this.state.comment.warning}
                </span>
              </div>
            </div>
            <div
              onClick={() => {
                this.sendContactMessage();
              }}
              className="send-contact"
            >
              <div>
                <div className="send-button">Send</div>
              </div>
            </div>
            <span className="sent-message">
              {this.state.messageSent
                ? translate.messageSent[settings.lang]
                : ""}
            </span>
          </div>
        </div>
      </content>
    );
  }
}

export default Content;
