import React, { Component } from "react";
import settings from "../../settings.json";
import { withRouter, Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import "./cabinOffers.scss";
import { defaultImage } from "../../assets/default-placeholder.png";
import translate from "../../translate.json";

class CabinOffers extends Component {
  cabinOffers = [];
  defaultImage = defaultImage;
  selectedOffers = [];
  noOffers = false;
  constructor(props) {
    super(props);
    this.axios = require("axios");
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
    };
    this.state = JSON.parse(sessionStorage.getItem("currentState"));
    this.state.pcs = 1;
    this.state.offers = this.state.offers ? this.state.offers : [];

    this.getCabinsOffer();
  }
  getCabinsOffer() {
    var id = [];
    this.state.cabins.forEach((value) => {
      if (value.selected === true) {
        id.push(value.id);
      }
    });
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabinOffers.php",
        { id }
      )
      .then((response) => {
        if (response.data !== "") {
          this.cabinOffers = response.data;
        } else {
          this.noOffers = true;
        }
        this.cabinOffers.sort(this.compareId);
        this.setState({});
      });
  }
  compareId(a, b) {
    if (a.cabinId < b.cabinId) {
      return -1;
    }
    if (a.cabinId > b.cabinId) {
      return 1;
    }
    return 0;
  }
  selectOffers(value) {
    var index = this.state.offers.findIndex((x) => x.id === value.id);
    if (index !== -1) {
      this.state.offers.splice(index, 1);
    } else {
      this.state.offers.push(value);
    }
    this.setState({});
    sessionStorage.setItem("currentState", JSON.stringify(this.state));

  }
  addPeople(amount, index, maxOffers) {
    var indexOffers = this.state.offers.findIndex((x) => x.id === this.cabinOffers[index].id);
    // console.log(maxOffers)
    maxOffers = JSON.parse(maxOffers);
    if (this.cabinOffers[index].pcs + amount <= maxOffers && this.cabinOffers[index].pcs + amount >= 1) {
      this.cabinOffers[index].pcs = this.cabinOffers[index].pcs + amount;
      if (indexOffers === -1) {
        this.state.offers.push(this.cabinOffers[index])
        this.setState({});
      } else {
        var offers = this.state.offers;
        offers[indexOffers].psc = this.cabinOffers[index].pcs;
        this.setState({ offers });
      }
    }

    sessionStorage.setItem("currentState", JSON.stringify(this.state));
  }
  render() {
    var cabinId = [];
  
    var offers = this.cabinOffers.filter(x => !x.name.toLocaleLowerCase().includes("cleaning fee")).sort((a, b) => b.required - a.required).map((value, index) => {
      // console.log(value)

      if (value.maxOffers === "") {
        value.maxOffers = "999"
      }
      if ((value.unit === "1" || value.unit === "4") && value.pcs === undefined) {
        value.pcs = 1;
      }
      var cabinIndex = this.state.cabins.findIndex(
        (x) => x.id === value.cabinId && x.selected === true
      );

      if (cabinIndex !== -1) {
        var headline = null;
        if (!cabinId.includes(value.cabinId)) {
          headline = (
            <tr>
              <th>
                <h2>{this.state.cabins[cabinIndex].name}</h2>
              </th>
            </tr>
          );
          cabinId.push(value.cabinId);
        }
        if (value.required === "1") {
          var index = this.state.offers.findIndex((x) => x.id === value.id);
          if (index !== -1) {
          } else {
            this.state.offers.push(value);
          }
          sessionStorage.setItem("currentState", JSON.stringify(this.state));
        }

        return (
          <>
            <div className="cardOffer">
              <div>
                <div className="image">
                  <img
                    alt=""
                    src={
                      value.images !== ""
                        ? "" +
                        this.settings.protocol +
                        this.settings.domain +
                        "/static/uploads/large_" +
                        value.images +
                        "?" +
                        new Date().getTime()
                        : this.defaultImage
                    }
                  />
                </div>
                <div>
                  <h2>{settings.lang === "en" ? value.name : value.nameFo}</h2>
                  <p>{settings.lang === "en" ? value.description : value.descriptionFo}</p>
                </div>
              </div>
              <div className="actions">
                <h2>
                  {translate.price[settings.lang]} {value.price} DKK{" "}
                  {value.unit === "0" ? <p>{translate.perPer[settings.lang]}</p> : ""}
                  {value.unit === "2" ? <p> {translate.perPer[settings.lang]}, {translate.perDay[settings.lang]} </p> : ""}
                  {value.unit === "3" ? <p> {translate.perDay[settings.lang]} </p> : ""}
                </h2>
                {value.unit === "1" || value.unit === "4" ? <div className="select-pcs">
                  <div>
                    <div
                      className="add-people"
                      onClick={() => {
                        this.addPeople(+1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div
                      className="remove-people"
                      onClick={() => {
                        this.addPeople(-1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                  </div>
                  <div readOnly className="select-people select" type="text">
                    <div className="amount-people">
                      {value.pcs + " pcs."}
                    </div>
                  </div>

                </div>
                  : ''}

                <div
                  className={
                    this.state.offers.some((x) => x.id === value.id)
                      ? "selected all-btn"
                      : "all-btn"
                  }
                  onClick={() => {
                    if (value.required !== "1") {
                      this.selectOffers(value)
                    }
                  }}
                >
                  <div>
                    {this.state.offers.some((x) => x.id === value.id) ? (
                      value.required === "1" ? "Required" :
                        <span>
                          {translate.selected[settings.lang]} <FontAwesomeIcon icon={faCheck} />
                        </span>
                    ) : (
                      translate.select[settings.lang]
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <tbody key={index}>
              {headline}
              <tr>
                <td>
                  <div className="image">
                    <img
                      alt=""
                      src={
                        value.images !== ""
                          ? "" +
                          this.settings.protocol +
                          this.settings.domain +
                          "/static/uploads/large_" +
                          value.images +
                          "?" +
                          new Date().getTime()
                          : this.defaultImage
                      }
                    />
                  </div>
                </td>
                <td>
                  <h2>{settings.lang === "en" ? value.name : value.nameFo}</h2>
                  <p>{settings.lang === "en" ? value.description : value.descriptionFo}</p>
                </td>
                <td>
                  <h2>
                    {translate.price[settings.lang]} {value.price} DKK{" "}
                    {value.unit === "0" ? <p>{translate.perPer[settings.lang]}</p> : ""}
                    {value.unit === "2" ? <p> {translate.perPer[settings.lang]}, {translate.perDay[settings.lang]} </p> : ""}
                    {value.unit === "3" ? <p> {translate.perDay[settings.lang]} </p> : ""}
                  </h2>
                  {value.unit === "1" ? <div className="select-pcs">
                    <div
                      className="add-people"
                      onClick={() => {
                        this.addPeople(+1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div
                      className="remove-people"
                      onClick={() => {
                        this.addPeople(-1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div readOnly className="select-people select" type="text">
                      <div className="amount-people">
                        {value.pcs + " pcs."}
                      </div>
                    </div>

                  </div>
                    : ''}
                  {value.unit === "4" ? <div className="select-pcs">
                    <div
                      className="add-people"
                      onClick={() => {
                        this.addPeople(+1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div
                      className="remove-people"
                      onClick={() => {
                        this.addPeople(-1, index, value.maxOffers);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div readOnly className="select-people select" type="text">
                      <div className="amount-people">
                        {value.pcs + " Person"}
                      </div>
                    </div>
                  </div>
                    : ''}
                  <div
                    className={
                      this.state.offers.some((x) => x.id === value.id)
                        ? "selected all-btn"
                        : "all-btn"
                    }
                    onClick={() => {
                      if (value.required !== "1") {
                        this.selectOffers(value)
                      }
                    }}
                  >
                    <div>
                      {this.state.offers.some((x) => x.id === value.id) ? (
                        <span>
                          {translate.selected[settings.lang]} <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : (
                        translate.select[settings.lang]
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody> */}
          </>
        );
      } else {
        return null;
      }
    });
    if (this.noOffers) {
      return <Redirect to={"bookingdetails"} />;
    }
    return (
      <div id="cabinOffers">
        <table>{offers}</table>
        <Link
          onClick={() => {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
          to={"bookingdetails"}
        >
          <div className="btn-shadow"></div>
          <div className="all-btn next">
            <div>{translate.next[settings.lang]}</div>
          </div>
        </Link>
      </div>
    );
  }
}
export default withRouter(CabinOffers);
